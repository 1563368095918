/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

import LocationLinkedExperiences from 'components/locationLinkedExperiences/LocationLinkedExperiences';
import { SettingsPageContext } from 'contexts';
import { getLocationById } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { resetLocationDetail } from 'redux/locationsAndRegions/locationsAndRegionsSlice';

import LocationInfoForm from '../components/locationInfoForm/LocationInfoForm';
import GenerateServiceRequest from './ServiceRequest';

import './_locationDetailForm.scss';
import 'pages/onboarding/regOrgaisation/_regOrganisation.scss';

export default function LocationDetailForm() {
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const {
    location: { data: location },
  } = useSelector((state) => state.locationsAndRegions);
  const [searchParams, setSearchParams] = useSearchParams();
  const openLocationDropdown = searchParams.get('openLocationDropdown');

  const { subTabHandler } = useContext(SettingsPageContext);

  useEffect(() => {
    if (locationId && locationId !== 'new') {
      dispatch(getLocationById(locationId));
    }
    return () => {
      dispatch(resetLocationDetail());
    };
  }, [locationId]);

  useEffect(() => {
    if (location?.name)
      subTabHandler({
        isActive: true,
        heading: location.name,
        updateActiveTab: true,
      });
  }, [location]);

  useEffect(() => {
    if (openLocationDropdown) {
      searchParams.delete('openLocationDropdown');
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <div className="locationDetailContainer">
      <Collapse
        defaultActiveKey={
          (openLocationDropdown || locationId === 'new') && ['1']
        }
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={[
          {
            key: '1',
            label: (
              <div className="locationDetailContainerHeaderContent">
                <div>
                  <h4>{location?.name || 'Location Information'}</h4>
                  <p>Location information goes here.</p>
                </div>
                <GenerateServiceRequest />
              </div>
            ),
            children: <LocationInfoForm />,
          },
        ]}
      />
      {locationId !== 'new' && <LocationLinkedExperiences />}
    </div>
  );
}
