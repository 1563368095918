import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { SurveyPreview } from 'components';
import LocationLinkedExperiences from 'components/locationLinkedExperiences/LocationLinkedExperiences';
import Icon from 'components/ui/icon/Icon';
import { OnboardingBranchContext } from 'contexts';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { BRANCH_SETUP_STEP, URLS } from 'utils/constants';

import '../regOrgaisation/_regOrganisation.scss';
import './_branchFeedbackFormsSetupScreen.scss';

export default function BranchFeedbackFormsSetupScreen() {
  const navigate = useNavigate();
  const { locationId } = useParams();
  const { branch } = useContext(OnboardingBranchContext);

  const {
    surveyDetail: { data: surveyDetailById },
  } = useSelector((s) => s.surveys);

  const [linkedExperiences, setLinkedExperiences] = useState([]);

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={BRANCH_SETUP_STEP} />
        <OnboardingLayout.Form
          title="Location Forms"
          subtitle="Connect forms with your locations."
        >
          <div className="onboarding-org">
            <Icon type="work" />
            <hgroup>
              <p />
              <h2>{branch?.name}</h2>
            </hgroup>
          </div>
          <div className="expListContainer">
            <LocationLinkedExperiences
              onChangeLinkedExperiences={setLinkedExperiences}
              isOnboarding
            />
          </div>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          disableContinue={!linkedExperiences.length}
          continueText="Next"
          onClickBack={() => navigate(`${URLS.BRANCH_SETUP_URL}/${locationId}`)}
          onClickContinue={() => navigate(URLS.BRANCH_LIST_URL)}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        {surveyDetailById ? <SurveyPreview survey={surveyDetailById} /> : null}
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}
