import dayjs from 'dayjs';

import { organizationNameRegex, phoneNumberRegex } from 'utils/constants/regex';

import Yup from './Yup';
import { customExperienceSchema } from './common';

const organizationTypeSchema = customExperienceSchema('Organization type');

const regOrganizationSchema = Yup.object().shape({
  name: Yup.string()
    .requiredTrimmed(
      'Organization name cannot be only blank spaces.',
      'Organization name is required!',
    )
    .matches(
      organizationNameRegex,
      'Invalid name. Only alphabets, numeric, and special characters . , - & # ( ) are allowed.',
    )
    .test(
      'contains-alphanumeric',
      'Organization name cannot contain only special characters.',
      (value) => /[a-zA-Z0-9]/.test(value),
    )
    .range(
      1,
      128,
      'Must be at least 1 character.',
      'Must not exceed 128 characters',
    ),
  logo: Yup.mixed().nullable(),
  type: organizationTypeSchema,
  email: Yup.string().customEmail().notRequired(),
  tnc: Yup.boolean().oneOf(
    [true],
    'You need to agree to our terms and conditions to proceed!',
  ),
  phone_number: Yup.string()
    .notRequired()
    .matches(phoneNumberRegex, 'Please enter a valid phone number.')
    .range(
      9,
      15,
      'Must be at least 9 characters.',
      'Must not exceed 15 characters',
    ),
});

export default regOrganizationSchema;

export const OrgAPIKeyScehma = Yup.object().shape({
  expiration_date: Yup.date()
    .nullable()
    .test(
      'is-future',
      'Expiration date must be in the future',
      (value) => !value || dayjs(value).isAfter(dayjs()),
    ),
  is_active: Yup.boolean().nullable(),
});
