import { useEffect, useRef } from 'react';

const useEffectAfterMount = (effect, dependencies) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return undefined; // Explicitly return undefined
    }
    const cleanup = effect();
    return typeof cleanup === 'function' ? cleanup : undefined; // Ensure a consistent return
  }, dependencies);
};

export default useEffectAfterMount;
