import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';

import { useBranchUpdateQuery } from 'components/queries';
import { getLocationById } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { resetLocationDetail } from 'redux/locationsAndRegions/locationsAndRegionsSlice';
import { branchSetupSchema } from 'utils/validations';

const LOCATION_DATA = {
  name: '',
  street_address: '',
  state: '',
  city: '',
  country: '',
  zip_code: '',
  region: '',
};

export default function useLocationSetup({
  isOnboarding = false,
  locationId,
  location,
  onUpdate,
}) {
  const dispatch = useDispatch();
  const googleReviewsPriceRef = useRef();
  const {
    location: { data: locationData },
  } = useSelector((state) => state.locationsAndRegions);

  const fetchCurrentLocation =
    locationId && +locationId !== (location ?? locationData ?? {}).id;
  const [isLoading, setIsLoading] = useState(fetchCurrentLocation);

  const handleLocationUpdate = (response) => {
    if (response && !isOnboarding) {
      googleReviewsPriceRef.current = {
        success: true,
        message: response?.google_reviews_message,
      };
    }
    onUpdate?.(response);
  };

  const {
    isLoading: isSubmitting,
    onSubmit,
    errors,
  } = useBranchUpdateQuery(locationId, handleLocationUpdate, isOnboarding);

  const getLocationFormikData = (data) => {
    if (data)
      return {
        ...data,
        previousGooglePlaceId: data.google_place_id, // To check if Location google place id is changed or not
        region: data.region?.id,
        country: data.country?.id,
        city: data.city?.id,
        state: data.state?.id,
      };
    return { ...LOCATION_DATA };
  };

  const formik = useFormik({
    initialValues: !fetchCurrentLocation
      ? getLocationFormikData(location ?? locationData)
      : { ...LOCATION_DATA },
    onSubmit,
    validationSchema: branchSetupSchema({
      isRegionRequired: isOnboarding || !locationId,
    }),
    validateOnChange: true,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (locationData) {
      formik.resetForm({
        values: getLocationFormikData(locationData),
        status: googleReviewsPriceRef.current, // To maintain status value used in Google Reviews price modal.
      });
    }
  }, [locationData]);

  useEffect(() => {
    if (fetchCurrentLocation) {
      setIsLoading(true);
      dispatch(getLocationById(locationId)).then(() => {
        setIsLoading(false);
      });
    }
    return () => {
      dispatch(resetLocationDetail(null));
    };
  }, [locationId]);

  useEffect(() => {
    if (errors) {
      formik.setErrors(errors.response.data);
    }
  }, [errors]);

  return {
    formik,
    isLoading,
    isSubmitting,
    onSubmit,
  };
}
