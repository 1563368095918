import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm, Tooltip } from 'antd';

import CancelPaymentPlanModal from 'components/cancelPaymentPlan/CancelPaymentPlanModal';
import ChangePaymentPlanModal from 'components/changePaymentPlan/ChangePaymentPlanModal';
import RelativeTime from 'components/ui/relativeTime/RelativeTime';
import {
  getSubscribedPlan,
  resubscribePlan,
} from 'redux/payments/paymentsActions';

export default function CurrentPlan() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isActiveOrg } = useSelector((s) => s.authentication);
  const { data: subscribedPlan } =
    useSelector((s) => s.payments?.subscribedPlan) ?? {};
  const { isLoading: isResubscribing } =
    useSelector((s) => s.payments?.resubscribed) ?? {};
  const [isOpenChangePlanModal, setIsOpenChangePlanModal] = useState(false);
  const [isOpenCancelPlanModal, setIsOpenCancelPlanModal] = useState(false);

  const cancelPlanHandler = () => {
    setIsOpenCancelPlanModal(true);
  };

  const resubscribePlanHandler = () => {
    dispatch(resubscribePlan());
  };

  useEffect(() => {
    if (subscribedPlan && location.state?.openChangePaymentPlanModal) {
      setIsOpenChangePlanModal(true);
    }
    return () => {
      location.state = null;
    };
  }, [subscribedPlan]);

  useEffect(() => {
    dispatch(getSubscribedPlan());
  }, []);

  return (
    <>
      <div className="current-plan-container">
        <div>
          <img
            width={40}
            height={40}
            src="/assets/icons/icon-payment.svg"
            alt="icon"
          />
        </div>
        <div className="current-plan">
          <h4 className="plan-name">{subscribedPlan?.name}</h4>
          <div className="plan-details">
            <div>
              <h6>SUBSCRIPTION PERIOD</h6>
              <p>Monthly</p>
            </div>
            <div className="border-separator">
              <h6>STATUS</h6>
              <p>{subscribedPlan?.subscription_alert}</p>
            </div>
            <div className="border-separator">
              <h6>NEXT INVOICE DATE:</h6>
              <p>
                {subscribedPlan?.next_invoice_date ? (
                  <RelativeTime
                    date={subscribedPlan?.next_invoice_date}
                    tooltipDateFormat="MMM, DD YYYY"
                  />
                ) : (
                  <center>-</center>
                )}
              </p>
            </div>
            <div className="border-separator">
              <h6>ESTIMATED PAYMENT NEEDED:</h6>
              <p>
                {subscribedPlan?.next_invoice_price ? (
                  `$${subscribedPlan?.next_invoice_price}`
                ) : (
                  <p>$0</p>
                )}{' '}
                {subscribedPlan?.additional_cost
                  ? `($${subscribedPlan?.additional_cost} for additional responses)`
                  : null}
              </p>
            </div>
          </div>
          <div className="plan-actions">
            <button
              className="btn"
              type="button"
              onClick={() => setIsOpenChangePlanModal(true)}
            >
              <span>Change Current Plan</span>
              <Tooltip title="See details of your current plan">
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </button>
            {isActiveOrg && !subscribedPlan?.is_resubscribable && (
              <Popconfirm
                title="Are You Sure"
                description={`Do you want to cancel your ${subscribedPlan?.name} plan subscription`}
                onConfirm={cancelPlanHandler}
                okText="Yes"
                cancelText="No"
                cancelButtonProps={{ className: 'button button-secondary' }}
                okButtonProps={{ className: 'button button-primary' }}
              >
                <button className="btn" type="button">
                  <span>Cancel Plan</span>
                  <Tooltip title="Cancel your current plan">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                </button>
              </Popconfirm>
            )}
            {subscribedPlan?.is_resubscribable && (
              <Popconfirm
                title="Are You Sure"
                description={`Do you want to resubscribe the ${subscribedPlan?.name} plan`}
                onConfirm={resubscribePlanHandler}
                cancelButtonProps={{ className: 'button button-secondary' }}
                okButtonProps={{
                  loading: isResubscribing,
                  className: 'button button-primary',
                }}
              >
                <button className="btn" type="button">
                  <span>Resubscribe Plan</span>
                  <Tooltip title="Resubscribe your current plan">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                </button>
              </Popconfirm>
            )}
          </div>
        </div>
      </div>
      <ChangePaymentPlanModal
        onClose={() => setIsOpenChangePlanModal(false)}
        isOpenModal={isOpenChangePlanModal}
      />
      <CancelPaymentPlanModal
        onClose={() => setIsOpenCancelPlanModal(false)}
        isOpenModal={isOpenCancelPlanModal}
      />
    </>
  );
}
