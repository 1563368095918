/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, List, Switch } from 'antd';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { useFormik } from 'formik';
import { get, isEmpty } from 'lodash';

import { Stepper, UploadButton } from 'components';
import LocationSelector from 'components/locationSelector/LocationSelector';
import SurveySelector from 'components/surveySelector/SurveySelector';
import { Modal } from 'components/ui';
import { getParentEntityAction } from 'redux/authentication/authenticationActions';
import { setQrLinked } from 'redux/authentication/authenticationSlice';
import { getBranches } from 'redux/filters/filterActions';
import {
  createQr,
  getQr,
  initializeQr,
  updateQr,
} from 'redux/qrSetup/qrSetupActions';
import { removeSelectedQr } from 'redux/qrSetup/qrSetupSlice';
import { MAX_IMAGE_SIZE } from 'utils/constants';
import { compressImage, qrSetupHelper } from 'utils/helpers';
import { createMarkup } from 'utils/helpers/surveyHelpers';

import qrInfoImage from '../../../assets/images/qr-guide.png';
import ColorSelector from './ColorSelector';
import HOCRichText from './HOCRichText';

import '../_qrSetup.scss';

// stepper steps
const QR_SETUP = { name: 'Surveys' };
const BRANCH = { name: 'Location' };
const EXPERIENCE = { name: 'Customer Experience' };
const QR_SETUP_STEPS = [QR_SETUP, BRANCH, EXPERIENCE];
// end

function QRDetail({ formik }) {
  const { t } = useTranslation();
  const parentEntity = useSelector((s) => s.authentication.parentEntity);
  const { isInitializing, selectedQrCode } = useSelector((s) => s.qrSetup);

  const [isPreview, setIsPreview] = useState();

  const { values, setFieldValue } = formik;

  const uploadedFilesList = useMemo(() => {
    if (values.backdrop_image) {
      return [
        {
          uid: values.backdrop_image.uid ?? 'backdrop_image',
          name: values.backdrop_image.name ?? 'QR Code Background',
        },
      ];
    }
    return [];
  }, [values.backdrop_image]);

  const handleImageUpload = async (file) => {
    if (file) {
      try {
        const compressedImage = await compressImage({
          file,
          maxImageSize: MAX_IMAGE_SIZE,
          reductionPercentage: 10,
          quality: 0.9,
          minHeight: 1000,
          minWidth: 1000,
        });
        setFieldValue('backdrop_image', compressedImage);
        formik.setFieldError('backdrop_image', null);
      } catch (error) {
        if (error.isTooLarge)
          formik.setFieldError(
            'backdrop_image',
            'Image size must not exceed 10MB',
          );
      }
    } else {
      setFieldValue('backdrop_image', null);
    }
  };

  return (
    <div>
      <div className="qrDetail">
        <Stepper steps={QR_SETUP_STEPS} selectedStep={QR_SETUP} />
        <div className="heading mb-30">
          <span>
            <h3>{t('settings.qrSetup.addNew.linkedTo')}</h3>
            <p>
              {get(selectedQrCode, 'entity_experience.entity.name', '') &&
              get(selectedQrCode, 'entity_experience.experience.name', '')
                ? `${get(
                    selectedQrCode,
                    'entity_experience.entity.name',
                    '',
                  )} - ${get(
                    selectedQrCode,
                    'entity_experience.experience.name',
                    '',
                  )}`
                : 'Not Linked'}
            </p>
          </span>
        </div>
        <Form.Item name="qrcode_uuid" noStyle>
          <div />
        </Form.Item>
        <div style={{ marginBottom: 24 }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
            <div>
              <p>{t('settings.qrSetup.addNew.chooseBackground')}</p>
              <div className="chooseBack">
                <Form.Item className="m-0">
                  <ColorSelector
                    value={values.backdrop_color}
                    onChange={(color) => setFieldValue('backdrop_color', color)}
                  />
                </Form.Item>
                &nbsp;&nbsp;&nbsp;
                <Form.Item className="m-0">
                  <UploadButton
                    fileList={uploadedFilesList}
                    showUploadList={!formik.errors.backdrop_image}
                    maxCount={1}
                    onChange={handleImageUpload}
                  />
                </Form.Item>
              </div>
            </div>

            <div>
              <p>{t('settings.qrSetup.addNew.chooseTextColor')}</p>
              <Form.Item className="m-0">
                <ColorSelector
                  disabledAlpha
                  value={values.foreground_color}
                  onChange={(color) => setFieldValue('foreground_color', color)}
                />
              </Form.Item>
            </div>
          </div>
          {formik.errors.backdrop_image ? (
            <span className="control-error">
              {formik.errors.backdrop_image}
            </span>
          ) : null}
        </div>
      </div>

      <div className="mb-30">
        <span>
          {t('settings.qrSetup.addNew.businessLogo')} &nbsp;&nbsp;
          <Form.Item>
            <Switch
              checked={values.include_logo}
              onChange={(checked) => setFieldValue('include_logo', checked)}
            />
          </Form.Item>
        </span>
      </div>
      {isInitializing || isEmpty(selectedQrCode) ? (
        <center>
          <Spinner />
        </center>
      ) : (
        <div className="mb-30 qrFormContainer">
          <div className="mb-30">
            <List bordered>
              <List.Item
                className="qrFormInner"
                style={{
                  // eslint-disable-next-line
                  backgroundImage: values.backdrop_image
                    ? typeof values.backdrop_image === 'string'
                      ? `url(${values.backdrop_image})`
                      : `url(${URL.createObjectURL(values.backdrop_image)})`
                    : null,
                  backgroundColor: values.backdrop_color,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                {values.include_logo && (
                  <div
                    className="organisation"
                    style={{
                      color: values.foreground_color,
                    }}
                  >
                    <img src={parentEntity.logo} alt="" />
                    <h3>{parentEntity.name}</h3>
                  </div>
                )}
                <div className="mt-20 rte">
                  <Form.Item
                    name="name"
                    style={{
                      color: values.foreground_color,
                    }}
                  >
                    <HOCRichText
                      placeholder="Title"
                      isPreview={isPreview}
                      editorState={values.name}
                      onChange={(state) => setFieldValue('name', state)}
                    />
                  </Form.Item>
                </div>
                <div className="qrCode">
                  <Form.Item>
                    <img
                      alt=""
                      src={values.qrcode_image}
                      className="qr"
                      width={200}
                      height={200}
                    />
                  </Form.Item>
                  <img
                    className="info"
                    src={qrInfoImage}
                    alt=""
                    width={60}
                    height={80}
                    style={
                      values.foreground_color
                        ? {
                            filter: qrSetupHelper.tintImage(
                              values.foreground_color,
                            ).filter,
                          }
                        : {}
                    }
                  />
                </div>
                <div className="rte mt-20 ">
                  <Form.Item
                    name="description"
                    style={{
                      color: values.foreground_color,
                    }}
                  >
                    <HOCRichText
                      placeholder="Description"
                      isPreview={isPreview}
                      editorState={values.description}
                      onChange={(state) => setFieldValue('description', state)}
                    />
                  </Form.Item>
                </div>
              </List.Item>
            </List>
          </div>
          <button
            type="button"
            className="button button-primary"
            onClick={() => setIsPreview(!isPreview)}
          >
            {isPreview ? 'Edit' : 'Preview'}
          </button>
        </div>
      )}
    </div>
  );
}

function AttachLocationToQRCode({ formik }) {
  const { t } = useTranslation();

  const { values, setFieldValue } = formik;

  return (
    <div>
      <div className="qrDetail">
        <Stepper steps={QR_SETUP_STEPS} selectedStep={BRANCH} />
        <div className="heading mb-30">
          <span>
            <h3>{t('settings.qrSetup.addNew.chooseLocation')}</h3>
            <p>{t('settings.qrSetup.addNew.chooseLocationSubTitle')}</p>
          </span>
        </div>
        <Form.Item>
          <LocationSelector
            value={values.location}
            onChange={(value) => setFieldValue('location', value)}
          />
        </Form.Item>
      </div>
    </div>
  );
}

function AttachExperienceToQRCode({ formik }) {
  const { t } = useTranslation();
  const { branchList } = useSelector((state) => state.filters);

  const { values, setFieldValue } = formik;

  return (
    <div>
      <div className="qrDetail">
        <Stepper steps={QR_SETUP_STEPS} selectedStep={EXPERIENCE} />
        <div className="heading mb-30">
          <span>
            <h3>{t('settings.qrSetup.addNew.chooseExperience')}</h3>
            <p>{t('settings.qrSetup.addNew.chooseExperienceSubTitle')}</p>
          </span>
        </div>
        <Form.Item>
          <SurveySelector
            experiences={get(
              branchList.find((b) => b.id === values.location?.id),
              'experiences',
              [],
            )}
            value={values.entity_experience}
            onChange={(value) => setFieldValue('entity_experience', value)}
          />
        </Form.Item>
        <div>
          <p>
            {t('settings.qrSetup.addNew.loopbackConfirmation')}
            &nbsp;&nbsp;&nbsp;
            <Form.Item noStyle name="is_recursive">
              <Switch
                checked={values.is_recursive}
                onChange={(checked) => setFieldValue('is_recursive', checked)}
              />
            </Form.Item>
          </p>
        </div>
      </div>
    </div>
  );
}

function FinishQrSetup({ onClose, printQr }) {
  const { t } = useTranslation();
  return (
    <div className="lastStep">
      <FontAwesomeIcon icon={faCheck} color="green" size="3x" />
      <h3>{t('settings.qrSetup.addNew.success.title')}</h3>
      <p>
        {t('settings.qrSetup.addNew.success.subTitle')} <br />
        Explore now!
      </p>
      <div className="action">
        <button className="cstm-btn secondary-cstm-btn" onClick={onClose}>
          {t('settings.qrSetup.addNew.success.close')} <br />
        </button>
        <button className="cstm-btn primary-cstm-btn" onClick={printQr}>
          {t('settings.qrSetup.addNew.success.print')} <br />
        </button>
      </div>
    </div>
  );
}

export default function QrDetailModal({
  onClose,
  selectedQr,
  onSaveAfter,
  onPrint,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [qrToPrint, setQrToPrint] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const { isCreating, selectedQrCode } = useSelector((s) => s.qrSetup);

  const getRichTextValAsCompState = (value = '') => {
    if (!value || isEmpty(value)) return EditorState.createEmpty();
    return EditorState.createWithContent(stateFromHTML(value));
  };

  const onSubmit = async (vals) => {
    vals = {
      ...vals,
      name: vals.name && createMarkup(vals.name.getCurrentContent()).__html,
      description:
        vals.description &&
        createMarkup(vals.description.getCurrentContent()).__html,
    };

    if (selectedQr.isDuplicate && typeof vals.backdrop_image === 'string') {
      vals.duplicate_id = vals.id; // To handle the duplicate background image.
    }

    if (!vals.backdrop_image || typeof vals.backdrop_image === 'string') {
      delete vals.backdrop_image;
    }

    const formData = new FormData();
    Object.entries(vals).forEach(([k, v]) => formData.append(k, v));
    formData.append('serial_number', selectedQrCode.serial_number);
    if (selectedQr?.id) {
      dispatch(updateQr({ id: selectedQr?.id, params: formData })).then(
        (action) => {
          if (!action.error) {
            setCurrentStep(3);
            setQrToPrint(action.payload);
            onSaveAfter();
          }
        },
      );
    } else {
      dispatch(createQr(formData)).then((action) => {
        if (!action.error) {
          setCurrentStep(3);
          setQrToPrint(action.payload);
          onSaveAfter();
        }
      });
    }
    dispatch(setQrLinked(true));
  };

  const formik = useFormik({
    initialValues: {
      qrcode_uuid: '',
      backdrop_color: '',
      backdrop_image: null,
      foreground_color: '',
      include_logo: false,
      name: getRichTextValAsCompState(''),
      description: getRichTextValAsCompState(''),
      location: null,
      entity_experience: null,
      is_recursive: false,
    },
    onSubmit,
  });

  const { values } = formik;

  const printQr = () => {
    onPrint(qrToPrint);
    onClose();
  };

  const onNext = () => {
    if (currentStep + 1 < QR_SETUP_STEPS.length) {
      return setCurrentStep(currentStep + 1);
    }
    return formik.handleSubmit();
  };

  const onBack = () => {
    if (currentStep > 0) {
      return setCurrentStep(currentStep - 1);
    }
    return onClose();
  };

  useEffect(() => {
    if (selectedQr === 'new') {
      dispatch(initializeQr());
    } else if (selectedQr.isDuplicate && selectedQr.qrIdToDuplicate) {
      dispatch(initializeQr({ duplicate_id: selectedQr.qrIdToDuplicate }));
    } else dispatch(getQr(selectedQr.id));
  }, [selectedQr]);

  useEffect(() => {
    if (!isEmpty(selectedQrCode)) {
      formik.setValues({
        ...selectedQrCode,
        entity_experience: selectedQrCode?.entity_experience?.id,
        location: selectedQrCode?.entity_experience?.entity,
        name: getRichTextValAsCompState(
          selectedQrCode?.name || t('settings.qrSetup.addNew.qrScanText'),
        ),
        description: getRichTextValAsCompState(
          selectedQrCode?.description || t('settings.qrSetup.addNew.thankYou'),
        ),
      });
    }
  }, [selectedQrCode]);

  useEffect(() => {
    dispatch(getParentEntityAction());
    dispatch(getBranches({ extra_data: true, with_experiences: true }));
    return () => dispatch(removeSelectedQr());
  }, []);

  return (
    <Form onFinish={formik.handleSubmit}>
      <Modal
        open
        onClose={onClose}
        backDropClose={false}
        className="qr-detail-modal"
        isCurveredBorderedModal
      >
        <Modal.Header
          title={t('settings.qrSetup.addNew.title')}
          onClose={onClose}
        />
        <Modal.Body>
          {currentStep === 0 ? <QRDetail formik={formik} /> : null}
          {currentStep === 1 ? (
            <AttachLocationToQRCode formik={formik} />
          ) : null}
          {currentStep === 2 ? (
            <AttachExperienceToQRCode formik={formik} />
          ) : null}
          {currentStep === 3 ? (
            <FinishQrSetup onClose={onClose} printQr={printQr} />
          ) : null}
        </Modal.Body>
        {currentStep !== 3 ? (
          <Modal.Footer
            isCurveredBorderedModal
            primaryLabel="Continue"
            primaryAction={onNext}
            disablePrimaryAction={
              (currentStep === 1 && isEmpty(values.location)) ||
              (currentStep === 2 && (!values.entity_experience || isCreating))
            }
            isLoading={isCreating}
            secondaryLabel="Back"
            secondaryAction={onBack}
            disableSecondaryAction={isCreating}
          />
        ) : null}
      </Modal>
    </Form>
  );
}
