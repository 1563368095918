import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { convertToRaw } from 'draft-js';
import { useFormik } from 'formik';

import { RichTextEditor } from 'components/richTextEditor';
import SURVEY_LINK_DECORATOR from 'components/richTextEditor/Decorators';
import { Modal, Spinner } from 'components/ui';
import SettingsPageContext from 'contexts/SettingsPageContext';
import { ManageEmailsContext } from 'pages/manageEmails/ManageEmails';
import {
  createInviteTemplate,
  getInviteTemplateById,
  getInviteTemplates,
  updateInviteTemplateById,
} from 'redux/emailManagement/emailManagementActions';
import { PAGINATION_AND_SORTING_PARAMS } from 'utils/constants';
import {
  EMAIL_EDITOR_TOOLBAR_CONFIG,
  FEEDBACK_TEMPLATES_OVERLAY,
} from 'utils/constants/manageEmails';
import { contentStateToHtml } from 'utils/helpers';
import { emailEditorBlockRenderFunc } from 'utils/helpers/emailsManagementHelper';
import {
  findSurveyLink,
  insertSurveyLink,
} from 'utils/helpers/richTextEditorHelpers';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { inviteTemplateValidationSchema } from 'utils/validations/emailMangementValidations';

import FeedbackFormSelector from '../FeedbackFormSelector';
import TemplateOverlays from '../TemplateOverlays/TemplateOverlays';

import '../_manageEmailModals.scss';

function InviteTemplateModal() {
  const { t } = useTranslation();
  const editorRef = useRef();
  const dispatch = useDispatchWithErrors();
  const {
    inviteTemplate: { data: inviteTemplate, loading: inviteTemplateLoading },
  } = useSelector((s) => s.emailManagement);

  const { params, setParams } = useContext(SettingsPageContext);
  const { setIsOpenInviteTemplateModal, inviteTemplateIdRef } =
    useContext(ManageEmailsContext);
  const inviteTemplateId = inviteTemplateIdRef.current;

  const [loading, setLoading] = useState(false);
  const [showFeedbackTemplates, setShowFeedbackTemplates] = useState(false);

  const handleModalClose = () => {
    inviteTemplateIdRef.current = null;
    setIsOpenInviteTemplateModal(false);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email_json_data: '',
      entity_experience: '',
    },
    validationSchema: inviteTemplateValidationSchema,
    onSubmit: async (vals) => {
      const htmlMessage = contentStateToHtml(vals.email_json_data);
      const data = {
        ...vals,
        html_message: htmlMessage,
        email_json_data: convertToRaw(vals.email_json_data),
        entity_experience: vals.entity_experience?.id,
      };
      setLoading(true);
      if (inviteTemplateId) {
        const { isError } = await dispatch(
          updateInviteTemplateById({
            id: inviteTemplateId,
            data,
          }),
          formik,
        );
        if (!isError) {
          toast.success(t('settings.manageEmails.toasts.updateInviteTemplate'));
          handleModalClose();
          dispatch(getInviteTemplates(params));
        }
      } else {
        const { isError } = await dispatch(createInviteTemplate(data), formik);
        if (!isError) {
          setParams({ ...PAGINATION_AND_SORTING_PARAMS });
          toast.success(t('settings.manageEmails.toasts.createInviteTemplate'));
          handleModalClose();
        }
      }
      setLoading(false);
    },
  });

  const openFeedbackTemplates = () => {
    setShowFeedbackTemplates(FEEDBACK_TEMPLATES_OVERLAY);
  };

  const addSurveyLinkHandler = async () => {
    const editorState = editorRef.current?.getEditorState();
    const newEditorState = insertSurveyLink(editorState);
    editorRef.current.updateState(newEditorState);
  };

  useEffect(() => {
    if (inviteTemplate && inviteTemplate?.id === inviteTemplateId) {
      formik.resetForm({
        values: { ...inviteTemplate },
      });
      editorRef.current.updateState(
        inviteTemplate.email_json_data ?? inviteTemplate.html_message,
      );
    }
  }, [inviteTemplateId, inviteTemplate]);

  useEffect(() => {
    if (inviteTemplateId) {
      dispatch(getInviteTemplateById(inviteTemplateId));
    }
  }, [inviteTemplateId]);

  const { touched, errors, values } = formik;

  const isSurveyLinkPresent = useMemo(() => {
    return findSurveyLink(values.email_json_data);
  }, [values.email_json_data]);

  return (
    <Modal
      open
      onClose={handleModalClose}
      backDropClose={false}
      className="email-management-modals"
    >
      {inviteTemplateLoading || loading ? <Spinner /> : null}
      <Modal.Header
        title="Email Template"
        isEditable={inviteTemplateId}
        formik={formik}
        inputName="name"
        onClose={handleModalClose}
      />
      <Modal.Body className="invite-template-modal">
        {inviteTemplateId ? null : (
          <div className="control-group">
            <label
              className={`control ${
                touched.name && errors.name ? 'control-invalid' : ''
              }`}
            >
              <span className="required-field">
                Template Name <span>*</span>
              </span>
              <input
                type="text"
                name="name"
                placeholder="Enter"
                onChange={formik.handleChange}
                value={values.name}
              />
              {touched.name && (
                <span className="control-error">{errors.name}</span>
              )}
            </label>
          </div>
        )}
        <div className="email-body">
          <RichTextEditor
            setState={(emailJsonData) => {
              formik.setFieldValue('email_json_data', emailJsonData);
            }}
            content={values.email_json_data}
            error={formik.submitCount > 0 ? errors.email_json_data : ''}
            toolbarConfig={EMAIL_EDITOR_TOOLBAR_CONFIG}
            toolbarOnFocus={false}
            ref={editorRef}
            errorStyles={{}}
            customDecorators={[SURVEY_LINK_DECORATOR]}
            blockRendererFn={emailEditorBlockRenderFunc}
          />
          <button
            type="button"
            className="mt-2 cstm-btn secondary-cstm-btn add-cstm-survey-link"
            onClick={addSurveyLinkHandler}
            disabled={isSurveyLinkPresent}
          >
            Link Survey
          </button>
        </div>
        <div className="add-templates-btns">
          <div>
            <FeedbackFormSelector
              openFeedbackTemplates={openFeedbackTemplates}
              selectedFeedbackForm={values.entity_experience?.title}
            />
          </div>
          {touched.entity_experience && (
            <span className="control-error">{errors.entity_experience}</span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        isLoading={loading}
        primaryAction={formik.submitForm}
        primaryLabel={inviteTemplateId ? 'Update Template' : 'Create Template'}
        disablePrimaryAction={loading}
      />
      {showFeedbackTemplates ? (
        <TemplateOverlays
          formik={formik}
          currentOverlayPreview={showFeedbackTemplates}
          setCurrentOverlayPreview={setShowFeedbackTemplates}
        />
      ) : null}
    </Modal>
  );
}

export default InviteTemplateModal;
