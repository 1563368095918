import React from 'react';
import { Route } from 'react-router-dom';

import { OnboardingNavigator } from 'layouts';
import OnboardingBranchLayout from 'layouts/onboardingBranchLayout/OnboardingBranchLayout';
import {
  BranchActivation,
  BranchFeedbackFormsSetupScreen,
  BranchListScreen,
  BranchSetupScreen,
  CreateSurvey,
  CreateSurveyTemplate,
  ListSurveyTemplates,
  OnboardingSaasAgreement,
  PaymentPlans,
  PaymentSetupScreen,
  RegOrganisation,
  Signup,
  SuccessfulSignUp,
  SurveysList,
  VerifyEmail,
  WellcomeScreen,
} from 'pages';
import { URLS } from 'utils/constants';
import { SIGNUP_BRANCH_URL } from 'utils/constants/urlConstants';
import { AuthRedirect, ProtectedRoute } from 'utils/hoc';

// trims of the /signup/ part from the path
const getPath = (path) => path.replace(`${URLS.SIGNUP_URL}/`, '');

// trims of the /signup/branch part from the path
const getBranchPath = (path) => path.replace(`${URLS.SIGNUP_BRANCH_URL}/`, '');

// separates out the onboarding routes to better manage them
export default function OnboardingRoutes() {
  return (
    <Route
      path={URLS.SIGNUP_URL.substring(1)}
      element={<OnboardingNavigator />}
    >
      <Route
        index
        element={
          <AuthRedirect to={URLS.DASHBOARD_URL}>
            <Signup />
          </AuthRedirect>
        }
      />
      <Route
        path={getPath(URLS.REGISTER_ORG_URL)}
        element={
          <ProtectedRoute
            isRestrictedToParentEntityAdmin
            Component={RegOrganisation}
          />
        }
      />

      <Route
        path={getPath(URLS.SIGNUP_WELCOME_URL)}
        element={
          <ProtectedRoute
            isRestrictedToParentEntityAdmin
            isRestrictedToEntityAdmin
            Component={WellcomeScreen}
          />
        }
      />
      <Route path={SIGNUP_BRANCH_URL} element={<OnboardingBranchLayout />}>
        <Route
          path={`${getBranchPath(URLS.BRANCH_FORM_SETUP_URL)}/:locationId?`}
          element={
            <ProtectedRoute
              isRestrictedToParentEntityAdmin
              Component={BranchFeedbackFormsSetupScreen}
            />
          }
        />
        <Route
          path={getBranchPath(URLS.BRANCH_LIST_URL)}
          element={
            <ProtectedRoute
              isRestrictedToParentEntityAdmin
              Component={BranchListScreen}
            />
          }
        />
        <Route
          path={getBranchPath(URLS.BRANCH_ACTIVATION_URL)}
          element={
            <ProtectedRoute
              isRestrictedToParentEntityAdmin
              Component={BranchActivation}
            />
          }
        />
        <Route
          path={`${getBranchPath(URLS.BRANCH_SETUP_URL)}/:locationId?`}
          element={
            <ProtectedRoute
              isRestrictedToParentEntityAdmin
              Component={BranchSetupScreen}
            />
          }
        />
        <Route
          path={getBranchPath(URLS.CREATE_SURVEY_URL)}
          element={
            <ProtectedRoute
              isRestrictedToParentEntityAdmin
              Component={CreateSurvey}
            />
          }
        />
        <Route
          path={getBranchPath(URLS.SURVEY_TEMPLATES)}
          element={
            <ProtectedRoute isSuperuser Component={ListSurveyTemplates} />
          }
        />
        <Route
          path={getBranchPath(URLS.CREATE_SURVEY_TEMPLATE_URL)}
          element={
            <ProtectedRoute isSuperuser Component={CreateSurveyTemplate} />
          }
        />
      </Route>

      <Route path={getPath(URLS.VERIFY_EMAIL_URL)} element={<VerifyEmail />} />
      <Route
        path={getPath(URLS.SUCCESSFUL_SIGNUP)}
        element={<SuccessfulSignUp />}
      />
      <Route
        path={getPath(URLS.ADD_CUSTOMER_FEEDBACK_FORM_URL)}
        element={
          <ProtectedRoute
            isRestrictedToParentEntityAdmin
            Component={SurveysList}
          />
        }
      />
      <Route
        path={getPath(URLS.PAYMENT_PLANS_URL)}
        element={<PaymentPlans />}
      />

      <Route
        path={getPath(URLS.PAYMENT_URL)}
        element={
          <ProtectedRoute
            isRestrictedToParentEntityAdmin
            Component={PaymentSetupScreen}
          />
        }
      />

      <Route
        path={getPath(URLS.ONBOARDING_SAAS_AGREEMENT)}
        element={
          <ProtectedRoute
            isRestrictedToParentEntityAdmin
            Component={OnboardingSaasAgreement}
          />
        }
      />
    </Route>
  );
}
