import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { Modal } from 'components/ui';
import { INSIGHTS_FILTERS, YEAR_TO_YEAR_FILTER } from 'utils/constants';
import insightFiltersSchema from 'utils/validations/insightsValidations';

import './_InsightsFiltersModal.scss';

function InsightsFiltersModal({ formik: insightsFormik }) {
  const { t } = useTranslation();
  const calendarPopupRef = useRef(null);
  const selectedYear = useRef(null);
  const [isOpenYearCalendar, setIsOpenYearCalendar] = useState(false);

  const formik = useFormik({
    initialValues: {
      filter: insightsFormik.values.filter,
    },
    validationSchema: insightFiltersSchema,
    onSubmit: (values) => {
      insightsFormik.setValues((v) => ({
        ...v,
        isOpenFiltersModal: false,
        filter: values.filter,
      }));
    },
  });

  const {
    values: { filter: selectedFilter },
    errors,
  } = formik;

  const handleModalClose = () => {
    insightsFormik.setFieldValue('isOpenFiltersModal', false);
  };

  const yearChangeHandler = (date, panel) => {
    if (panel === 'month') {
      selectedYear.current = date;
      formik.setFieldValue('filter', { ...selectedFilter, value: date });
      setIsOpenYearCalendar(false);
    }
  };

  const filterChangeHandler = (filter) => {
    let newFilter = filter;
    if (filter.id === YEAR_TO_YEAR_FILTER) {
      newFilter = {
        ...filter,
        value: selectedYear.current,
      };
    }
    formik.resetForm({
      values: {
        filter: newFilter,
      },
    });
  };

  const disabledYears = (current) => {
    const startYear = 2021; // Static as CX Meter don't have records older than 2021
    const previousYear = dayjs().year() - 1;
    return current.year() < startYear || current.year() > previousYear;
  };

  useEffect(() => {
    if (insightsFormik.values.isOpenFiltersModal) {
      formik.setFieldValue('filter', insightsFormik.values.filter);
    }
  }, [insightsFormik.values.isOpenFiltersModal]);

  return (
    <Modal
      open={insightsFormik.values.isOpenFiltersModal}
      onClose={handleModalClose}
      backDropClose={false}
      className="insights-filters-modal"
      contentContainerStyles={{ width: 500 }}
    >
      <Modal.Header
        title={t('insights.filterModal.title')}
        onClose={handleModalClose}
      />
      <Modal.Body>
        <p style={{ fontSize: 14, fontWeight: 400 }}>
          {t('insights.filterModal.subTitle')}
        </p>
        <div className="insights__filters__container">
          <div className="insights__filters">
            {INSIGHTS_FILTERS.map((filter) => (
              <button
                className={`insights__filter__pill ${
                  selectedFilter.id === filter.id ? 'selected' : ''
                }`}
                key={filter.id}
                onClick={() => filterChangeHandler(filter)}
              >
                {filter.label}
              </button>
            ))}
          </div>
          {formik.submitCount > 0 ? (
            <span className="control-error">{errors.filter?.id}</span>
          ) : null}
        </div>
        {selectedFilter.id === YEAR_TO_YEAR_FILTER ? (
          <div className="insight__year__filter" ref={calendarPopupRef}>
            <p className="m-0">Year</p>
            <DatePicker
              inputReadOnly
              mode="year"
              disabledDate={disabledYears}
              format="YYYY"
              className="insight__year__picker"
              value={selectedFilter.value}
              open={isOpenYearCalendar}
              onOpenChange={(open) => setIsOpenYearCalendar(open)}
              getPopupContainer={() => calendarPopupRef.current}
              onPanelChange={yearChangeHandler}
              allowClear={false}
            />
            {formik.submitCount > 0 ? (
              <span className="control-error">{errors.filter?.value}</span>
            ) : null}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer primaryAction={formik.submitForm} primaryLabel="Generate" />
    </Modal>
  );
}
export default InsightsFiltersModal;
