function TranslationLink({ children, to, title }) {
  return (
    <a
      className="hypertext-translation-link"
      href={to ?? '#'}
      target="_blank"
      rel="noreferrer"
      title={title ?? ''}
    >
      {children}
    </a>
  );
}

export default TranslationLink;
