import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Table } from 'antd';

import LocationStatusToggleModal from 'components/modals/LocationStatusToggleModal';
import { SettingsPageContext } from 'contexts';
import { getPaginatedLocations } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import {
  getPaymentPlans,
  getSubscribedPlan,
} from 'redux/payments/paymentsActions';
import { MANAGE_LOCATIONS_SETTINGS_URL } from 'utils/constants/urlConstants';
import { getAntdPaginationProps } from 'utils/helpers';
import { getLocationsColumns } from 'utils/helpers/locationsAndRegionsHelpers';

function Locations() {
  const [currentPlanLoading, setCurrentPlanLoading] = useState(false);
  const locationStatusModalRef = useRef();
  const navigate = useNavigate();
  const { locationId } = useParams();
  const dispatch = useDispatch();
  const {
    locations: { data: locations, loading },
  } = useSelector((state) => state.locationsAndRegions);

  const { params, onChangePagination, subTabHandler } =
    useContext(SettingsPageContext);

  useEffect(() => {
    dispatch(getPaginatedLocations({ ...params }));
  }, [params]);

  // This code will be removed once we have an API to get Current Active Plan API.
  const getCurrentActivePlan = async () => {
    setCurrentPlanLoading(true);
    await Promise.allSettled([
      dispatch(getPaymentPlans()),
      dispatch(getSubscribedPlan()),
    ]);
    setCurrentPlanLoading(false);
  };

  useEffect(() => {
    getCurrentActivePlan();
  }, []);

  const onSelectLocation = (location, openLocationDropdown) => {
    const { name } = location;
    subTabHandler({
      isActive: true,
      heading: name,
      subHeading: 'Manage and link customer experience with locations',
      hideSearchFilter: true,
    });
    const pathname = `${MANAGE_LOCATIONS_SETTINGS_URL}/${location.id}`;
    if (openLocationDropdown)
      navigate({
        pathname,
        search: `?openLocationDropdown=${openLocationDropdown}`,
      });
    else navigate(pathname);
  };

  const onChangeLocationStatus = (location) => {
    if (locationStatusModalRef.current) {
      locationStatusModalRef.current.open(location, () => {
        dispatch(getPaginatedLocations({ ...params }));
      });
    }
  };

  return !locationId ? (
    <>
      <Table
        className="left-align"
        scroll={{ x: 'max-content' }}
        columns={getLocationsColumns({
          onSelectLocation,
          onChangeLocationStatus,
        })}
        dataSource={locations.results}
        loading={loading || currentPlanLoading}
        pagination={{
          ...getAntdPaginationProps({
            data: locations,
            currentPage: params.page ?? 1,
            onChangePagination,
          }),
        }}
      />
      <LocationStatusToggleModal ref={locationStatusModalRef} />
    </>
  ) : (
    <Outlet />
  );
}

export default Locations;
