import React from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm, Tooltip } from 'antd';

import { Icon } from 'components/ui';

function FeedbackFormsTableActions({ survey, isTemplate, actions }) {
  const { t } = useTranslation();
  const { onEdit, onDuplicate, onDelete } = actions ?? {};
  const { is_deletable: isDeleteable } = survey ?? {};

  return (
    <span className="feedback-forms-table-actions">
      {onEdit ? (
        <span className="edit-action">
          <Icon type="visibility" onClick={() => onEdit(survey)} />
        </span>
      ) : null}
      {onDuplicate && !isTemplate && (
        <span className="duplicate-action">
          <Tooltip title="Create a duplicate">
            <FontAwesomeIcon
              icon={faCopy}
              onClick={() => onDuplicate(survey)}
            />
          </Tooltip>
        </span>
      )}
      {onDelete && !isTemplate && isDeleteable && (
        <span className="delete-action">
          <Popconfirm
            title={t('settings.feedbackForms.deletePopup.title')}
            description={t('settings.feedbackForms.deletePopup.subTitle')}
            onConfirm={() => onDelete(survey)}
            okText="Yes"
            cancelText="No"
            cancelButtonProps={{ className: 'button button-secondary' }}
            okButtonProps={{ className: 'button button-primary' }}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Popconfirm>
        </span>
      )}
    </span>
  );
}

const FeedbackFormsTableColumns = (isTemplate, actions) => [
  {
    title: 'NAME',
    dataIndex: 'title',
    onCell: () => ({
      style: {
        maxWidth: 300,
      },
    }),
  },
  {
    title: 'EXPERIENCE TYPE',
    dataIndex: 'experience',
    render: (experience) => experience?.name || 'N/A',
    onCell: () => ({
      style: {
        maxWidth: 300,
      },
    }),
  },
  {
    title: 'LINKED LOCATIONS',
    dataIndex: 'linked_experiences_count',
    onCell: () => ({
      style: {
        maxWidth: 300,
      },
    }),
  },
  {
    title: 'ACTIONS',
    key: 'actions',
    width: '4%',
    onCell: () => ({
      style: {
        maxWidth: 150,
      },
    }),
    render: (text, record) => (
      <FeedbackFormsTableActions
        isTemplate={isTemplate}
        actions={actions}
        survey={record}
      />
    ),
  },
];

export default FeedbackFormsTableColumns;
