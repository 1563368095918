/* eslint-disable no-shadow */

/* eslint-disable camelcase */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import leven from 'leven';
import { isEmpty, isNil, lowerCase, orderBy } from 'lodash';

import { SortableItem } from 'components/dragAndDrop';
import Ratings from 'components/ratings/Ratings';
import RichTextEditor from 'components/richTextEditor/RichTextEditor';
import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { CHECKBOX, RATING } from 'utils/constants/questionTypes';
import { arrayMove } from 'utils/helpers';
import { copyContentStateWithPrefix } from 'utils/helpers/richTextEditorHelpers';
import {
  cleanSimilarityErrors,
  formatSurveyOption,
  getOptionId,
  getPlainTextFromContentState,
} from 'utils/helpers/surveyHelpers';

import './_surveyBuilderAnswers.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const SORT_OPTIONS = [
  {
    name: 'A-Z',
    id: 'asc',
  },
  {
    name: 'Z-A',
    id: 'desc',
  },
];

function MultipleChoiceAnswer({
  question,
  sectionId,
  questionId,
  type,
  options = [],
  setOptions,
  isCompulsoryQuestion,
  onMaxAllowedAnswersChange,
  maxAllowedAnswers = 3,
  similarityThreshold = 80,
  setIsSimilarErrorActive,
  questionErrors: { options: optionsErrors = [] } = {},
  duplicateOptionsSections,
}) {
  const [similarityErrorIds, setSimilarityErrorIds] = useState({});
  const [sortOptionsBy, setSortOptionsBy] = useState('asc');
  const lastEditorRef = useRef();

  const { t } = useTranslation();

  const lastOption = options[options.length - 1];
  const otherOption =
    lastOption?.is_other_option && question.question_type !== RATING
      ? lastOption
      : null;
  const regularOptions = otherOption
    ? options.slice(0, options.length - 1)
    : options;
  const otherOptionError = otherOption
    ? optionsErrors[options.length - 1]?.error
    : null;

  const getStringSimilarity = (str1, str2) => {
    const distance = leven(str1, str2);
    const maxLength = Math.max(str1.length, str2.length);
    const similarity = ((maxLength - distance) / maxLength) * 100;
    return similarity.toFixed(2); // Returns similarity percentage with 2 decimal places
  };

  const handleSortOptions = (sortBy) => {
    const sortedOptions = orderBy(
      regularOptions,
      [(option) => getPlainTextFromContentState(option.label).toLowerCase()],
      [sortBy],
    );
    if (otherOption) {
      sortedOptions.push(otherOption);
    }
    setOptions(sortedOptions);
  };

  const checkSimilarity = (options, currentOption, id) => {
    duplicateOptionsSections.current = {};
    let isSimilar = false;
    currentOption = lowerCase(currentOption);

    options.forEach((option) => {
      const similarity = getStringSimilarity(
        lowerCase(option.label.getPlainText()),
        currentOption,
      );

      if (similarity > similarityThreshold) {
        setIsSimilarErrorActive(true);

        /*
        Example:
        - Initial State: {}
        - Option 1 is similar to Option 2:
          - {1: [2], 2:[1]}
        - Option 2 is similar to Option 3:
          - {1: [2], 2:[1, 3], 3: [2]}
        - Option 2 is no longer similar to Option 3:
          - {1: [2], 2:[1], 3:[]}
        - Bidirectional relation ensures that Option 2 is still in similar list
        */
        setSimilarityErrorIds((state) => {
          // add option to prev graph
          state[id] = state[id] || {};
          state[id][option.id] = {
            similarity: +similarity,
          };

          // add current to option graph
          state[option.id] = state[option.id] || {};
          state[option.id][id] = {
            similarity: +similarity,
          };

          return { ...state };
        });

        isSimilar = true;
      } else if (!isSimilar) {
        setSimilarityErrorIds((state) => {
          // remove option from prev graph
          if (state[id]) {
            delete state[id][option.id];
            if (Object.keys(state[id]).length === 0) {
              delete state[id];
            }
          }
          // remove current from option graph
          if (state[option.id]) {
            delete state[option.id][id];
            if (Object.keys(state[option.id]).length === 0) {
              delete state[option.id];
            }
          }

          return state;
        });
      }
    });
  };

  const handleSetChoice = (id, option_id, isOtherOption) => (choice) => {
    let otherOptions;
    // If Last option is focused with a value then add id because it will be null.
    const optionId = id ?? getOptionId(questionId, options.length);
    if (id) {
      otherOptions = options.filter((option) => option.id !== id);
      const idx = options.findIndex((ch) => ch.id === id);
      const temp = [...options];
      temp[idx] = formatSurveyOption(id, option_id, choice, isOtherOption);
      setOptions(temp);
    } else {
      otherOptions = options;
    }

    const currentChoiceText = choice.getPlainText();

    if (!id && !currentChoiceText.trim()) return; // To avoid unnecessary similarity check due to last option

    checkSimilarity(otherOptions, currentChoiceText, optionId);

    if (
      isEmpty(similarityErrorIds) ||
      Object.values(similarityErrorIds).every(
        (similarIds) => similarIds.length === 0,
      )
    ) {
      setIsSimilarErrorActive(false);
    }
  };

  const handleRemoveChoice = (optionIdx, optionId) => {
    if (isCompulsoryQuestion && options.length <= 3) {
      toast.warn('At least 3 options are required for this question.');
      return;
    }
    const temp = [...options];
    temp.splice(optionIdx, 1);
    setOptions(temp);

    const tempSimilarityErrorIds = { ...similarityErrorIds };

    Object.entries(tempSimilarityErrorIds).forEach(([source, target]) => {
      if (source === optionId) {
        delete tempSimilarityErrorIds[source];
      } else if (target[optionId]) {
        delete target[optionId];
        if (Object.keys(target).length === 0) {
          delete tempSimilarityErrorIds[source];
        }
      }
    });

    duplicateOptionsSections.current = {};
    setSimilarityErrorIds(tempSimilarityErrorIds);
    if (
      isEmpty(tempSimilarityErrorIds) ||
      Object.values(tempSimilarityErrorIds).every(
        (similarIds) => Object.keys(similarIds).length === 0,
      )
    ) {
      setIsSimilarErrorActive(false);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const itemIds = useMemo(() => options.map((item) => item.id), [options]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      setOptions(arrayMove(options, active.id, over.id));
    }
  };

  const handleOtherOptionContent = ({ target: { value } }) => {
    handleSetChoice(
      otherOption.id,
      otherOption.option_id,
      true,
    )(copyContentStateWithPrefix(value));
  };

  const focusLastEditor = () => {
    if (lastEditorRef.current) {
      lastEditorRef.current.focus();
    }
  };

  const handleAddOption = (type, isOtherOption) => {
    if (type === 'add') {
      const targetOption = isNil(otherOption) ? options.at(-1) : options.at(-2);

      if (!isOtherOption && !targetOption?.label?.hasText()) {
        focusLastEditor();
        return;
      }
      const choiceId = getOptionId(questionId, options.length);
      const newOption = formatSurveyOption(
        choiceId,
        null,
        copyContentStateWithPrefix(isOtherOption ? 'Other' : ''),
        isOtherOption,
      );
      handleSetChoice(
        newOption.id,
        newOption.option_id,
        true,
      )(copyContentStateWithPrefix(newOption.label));

      if (!isNil(otherOption)) {
        const newOptions = [...options];
        newOptions.splice(options.length - 1, 0, newOption);
        setOptions(newOptions);
      } else {
        setOptions([...options, newOption]);
      }
    } else {
      handleRemoveChoice(options.length - 1, otherOption.id);
    }
  };

  const getSimilarityError = (choice) => {
    const similarOptions = similarityErrorIds[choice.id];
    let error = '';
    if (similarOptions && Object.keys(similarOptions).length > 0) {
      const isDuplicateOption = Object.values(similarOptions).find((opt) => {
        error = t('formBuilder.similarity.error');
        return opt.similarity >= 100;
      });
      if (isDuplicateOption) {
        duplicateOptionsSections.current[sectionId] = true;
        error = t('formBuilder.similarity.duplicateError');
      }
    }
    return error;
  };

  useEffect(() => {
    if (
      question.question_type === RATING &&
      options.length &&
      !isEmpty(similarityErrorIds)
    ) {
      const cleanedSimilarityErrors = cleanSimilarityErrors(
        options,
        similarityErrorIds,
      );
      setSimilarityErrorIds(cleanedSimilarityErrors);
    }
  }, [question.question_type === RATING]);

  useEffect(() => {
    if (maxAllowedAnswers === 0) {
      onMaxAllowedAnswersChange(options.length);
    }
  }, [maxAllowedAnswers]);

  let otherOptionErrors = otherOptionError;
  if (otherOption && !otherOptionError) {
    otherOptionErrors = getSimilarityError(otherOption);
  }

  return (
    <div>
      {type === CHECKBOX ? (
        <label style={{ marginTop: '1rem' }}>
          <span>Max Allowed Choices: </span>
          <input
            type="number"
            min={1}
            max={options.length}
            value={maxAllowedAnswers}
            onChange={({ target: { value } }) => {
              value = value ? Number.parseInt(value, 10) : options.length;
              onMaxAllowedAnswersChange(value);
            }}
            style={{ width: '3rem', textAlign: 'center' }}
          />
        </label>
      ) : null}
      <div className="optionsSorterContainer">
        <h6>Options</h6>
        <div className="optionsSorterDropdown">
          <p>Sort By: </p>
          <SelectAndCreateOptionDropdown
            options={SORT_OPTIONS}
            selectedOption={sortOptionsBy}
            onSelect={handleSortOptions}
            onChange={(sortBy) => setSortOptionsBy(sortBy)}
            showSearch={false}
          />
        </div>
      </div>
      <DndContext
        id="choices-dnd-context"
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          {regularOptions?.map((choice, index) => {
            const error =
              optionsErrors[index]?.error ?? getSimilarityError(choice);
            return (
              <div key={index} className="choiceDragWrapper">
                <SortableItem key={choice.id} id={choice.id}>
                  <div className="choiceWrapper">
                    <div className="editor-container">
                      <RichTextEditor
                        ref={
                          index + 1 === regularOptions.length
                            ? lastEditorRef
                            : null
                        } // ref of last editor
                        setState={handleSetChoice(choice.id, choice.option_id)}
                        content={choice.label}
                        error={error}
                        focusEditor={index + 1 === regularOptions.length}
                        pasteFormatted
                      />
                    </div>
                    {type === RATING ? (
                      <Ratings disabled />
                    ) : (
                      <input
                        type={type}
                        id={`${type}-input-${choice.id}`}
                        disabled
                        className="choiceInput"
                      />
                    )}
                    {(!isCompulsoryQuestion || index > 2) &&
                      options?.length > 1 && (
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="crossIcon"
                          onClick={() => handleRemoveChoice(index, choice.id)}
                        />
                      )}
                  </div>
                </SortableItem>
              </div>
            );
          })}
        </SortableContext>
      </DndContext>
      <div className="last-choice">
        <span className="mock-dnd-icon" />
        <div className="last-choice-data-section">
          <span className="choiceWrapper">
            <div className="editor-container">
              <button
                onClick={() => handleAddOption('add', false)}
                className="add-option-button"
              >
                Add option
              </button>
            </div>
            {type === RATING ? (
              <Ratings disabled />
            ) : (
              <input type={type} disabled className="choiceInput" />
            )}
          </span>
        </div>
      </div>
      {question.question_type === RATING ? null : (
        <div className="other-option-container">
          <span className="mock-dnd-icon" />
          {isNil(otherOption) ? (
            <button
              className="cstm-btn text-cstm-btn"
              type="button"
              style={{ fontSize: 14 }}
              onClick={() => handleAddOption('add', true)}
            >
              Add Other
            </button>
          ) : (
            <div className="other-option">
              <div>
                <div className="other-option-inputs-container">
                  <input
                    className="other-option-text"
                    onChange={handleOtherOptionContent}
                    value={otherOption.label.getPlainText()}
                  />
                  <span style={{ marginLeft: -4 }}>:</span>
                  <input className="other-option-preview" disabled />
                </div>
                {otherOptionErrors ? (
                  <span style={{ fontSize: 15.2 }} className="control-error">
                    {otherOptionErrors}
                  </span>
                ) : null}
              </div>
              <FontAwesomeIcon
                icon={faXmark}
                className="crossIcon"
                onClick={() => handleAddOption('remove', true)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MultipleChoiceAnswer;
