import { useEffect, useState } from 'react';

export default function usePopStateListener() {
  const [historyChanged, setHistoryChanged] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      setHistoryChanged((prev) => !prev);
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return historyChanged;
}
