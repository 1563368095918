import { CHECKBOX } from 'utils/constants/questionTypes';
import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import {
  getPlainTextFromContentState,
  isChoicesQuestion,
} from 'utils/helpers/surveyHelpers';

import Yup from './Yup';
import { customExperienceSchema } from './common';

const getTextEditorErrors = (fieldName, editorText, min, max) => {
  const editorPlainText = getPlainTextFromContentState(editorText);
  let error = '';
  if (min > 0 && (!editorPlainText || editorPlainText === '')) {
    error = `Empty ${fieldName} is not allowed`;
  } else if (min > 0 && editorPlainText && editorPlainText.trim() === '') {
    error = `${fieldName} can't be only blank spaces`;
  } else if (min && editorPlainText.trim().length < min) {
    error = `Must be at least ${min} characters`;
  } else if (max && editorPlainText.trim().length > max) {
    error = `Must not exceed ${max} characters`;
  }
  return error;
};

const optionSchema = Yup.array().of(
  Yup.mixed().test('no-blank-option', (option, { createError, path, from }) => {
    const parentQuestion = from[0]?.value;
    // If Parent question is Inactive then validation is not required.
    if (!parentQuestion.is_active) return true;

    if (!parentQuestion || !isChoicesQuestion(parentQuestion.question_type))
      return true;
    const optionError = getTextEditorErrors('option', option?.label, 1, 256);
    if (optionError)
      return createError({
        message: {
          error: optionError,
          questionId: parentQuestion?.id,
        },
        path,
      });
    return true;
  }),
);

const questionSchema = Yup.array().of(
  Yup.object()
    .shape({
      label: Yup.mixed(),
      question_type: Yup.string().required(),
      options: optionSchema,
      max_number_of_choices: Yup.number(),
    })
    .test('custom-question-validation', (question, { createError, path }) => {
      // If question is Inactive then validation is not required.
      if (!question.is_active) return true;
      const questionType = question.question_type;
      let questionError = '';
      const questionTextError = getTextEditorErrors(
        'question',
        question?.label,
        1,
        256,
      );
      if (questionTextError) {
        questionError = questionTextError;
      } else if (
        isChoicesQuestion(questionType) &&
        question.options.length < 1
      ) {
        questionError = 'Minimum of 1 option is required';
      } else if (questionType === CHECKBOX) {
        if (
          question.max_number_of_choices < 1 ||
          !Number.isInteger(question.max_number_of_choices)
        ) {
          questionError = 'Max allowed choices is required';
        } else if (question.options.length < 3) {
          questionError = 'Minimum of 3 options are required';
        } else if (question.options.length < question.max_number_of_choices) {
          questionError = "Can't have lesser options than maximum allowed";
        }
      }
      if (questionError) {
        return createError({
          message: { error: questionError, questionId: question.id },
          path: `${path}.label`,
        });
      }
      return true;
    }),
);

const sectionSchema = Yup.array().of(
  Yup.object().shape({
    questions: questionSchema,
  }),
);

const experienceTypeSchema = customExperienceSchema('Experience name');

const getQuestionKey = (question) => {
  let label = question.label ?? '';
  if (question.label?.getPlainText) {
    label = question.label?.getPlainText();
  }
  return `${question.question_type}_${label.trim().toLowerCase()}`;
};

const surveySchema = Yup.object()
  .shape({
    title: Yup.string()
      .requiredTrimmed(
        "Survey title can't be only blank spaces.",
        'Survey title is required!',
      )
      .matches(
        onlyLettersAndSpacesRegex,
        'Survey title can only contain letters',
      )
      .range(
        1,
        256,
        'Must be at least 1 character',
        'Must not exceed 256 characters',
      ),
    experience: experienceTypeSchema,
    sections: sectionSchema,
    welcome_text: Yup.mixed().test(
      'custom-welcome-text-validation',
      (welcomeText) => {
        if (welcomeText === null || welcomeText === undefined) return true;
        const welcomePlainText = getPlainTextFromContentState(welcomeText);
        return welcomePlainText.trim().length <= 256;
      },
    ),
    respondent_question: Yup.object().shape({
      label: Yup.mixed().test(
        'respondent-question-text-validation',
        (respondentQuestion, { createError, path, parent }) => {
          if (!parent.is_active) return true;
          const respondentQuestionError = getTextEditorErrors(
            'Respondent Question',
            respondentQuestion,
            1,
            256,
          );

          if (respondentQuestionError) {
            return createError({
              message: respondentQuestionError,
              path,
            });
          }
          return true;
        },
      ),
      name_placeholder: Yup.string().when('is_active', {
        is: true,
        then: Yup.string()
          .requiredTrimmed(
            'Name Placeholder cannot be only blank spaces.',
            'Name Placeholder is required!',
          )
          .range(
            1,
            24,
            'Must be at least 1 character',
            'Must not exceed 24 characters',
          ),
        otherwise: Yup.string(),
      }),
      email_placeholder: Yup.string().when('is_active', {
        is: true,
        then: Yup.string()
          .requiredTrimmed(
            'Email Placeholder cannot be only blank spaces.',
            'Email Placeholder is required!',
          )
          .range(
            1,
            24,
            'Must be at least 1 character',
            'Must not exceed 24 characters',
          ),
        otherwise: Yup.string(),
      }),
      is_active: Yup.boolean(),
    }),
  })
  .test('custom-unique-question-labels-validation', ({ sections = [] }) => {
    const duplicateQuestionCount = {};

    sections.forEach((section) => {
      const question = section.questions[0];
      const key = getQuestionKey(question);
      if (!duplicateQuestionCount[key]) {
        duplicateQuestionCount[key] = 0;
      }
      duplicateQuestionCount[key] += 1;
    });
    const errors = [];
    sections.forEach((section, i) => {
      const question = section.questions[0];
      const path = `sections[${i}].questions[0].label`;
      const key = getQuestionKey(question);
      if (duplicateQuestionCount[key] > 1) {
        errors.push(
          new Yup.ValidationError(
            {
              error: 'Duplicate Questions are not allowed',
              questionId: question.id,
            },
            null,
            path,
          ),
        );
      }
    });
    if (errors.length > 0) {
      throw new Yup.ValidationError(errors);
    }
    return true;
  });

export default surveySchema;
