import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { SEARCH_FILTER } from 'components/filters/filterTypes';
import SettingsPageContext from 'contexts/SettingsPageContext';
import { PAGINATION_AND_SORTING_PARAMS } from 'utils/constants';

import './_horizantalTabs.scss';

function HorizantalTabs({ tabs, activeTab, hideSingleTab = true }) {
  const navigate = useNavigate();
  const {
    setSearchFilter,
    setParams,
    setActiveTab,
    setActiveSubTab,
    setSubTabsSearch,
  } = useContext(SettingsPageContext);

  const activeTabHandler = (tab) => {
    if (tab.id !== activeTab.id) {
      if (!tab.isSubTab) {
        setParams(PAGINATION_AND_SORTING_PARAMS);
        setSearchFilter({ [SEARCH_FILTER]: '' });
        setActiveTab(tab);
        navigate(tab.route, { state: { currentTab: tab } });
      } else {
        setSubTabsSearch({ [SEARCH_FILTER]: '' });
        setActiveSubTab((p) => ({
          ...tab,
          heading: p.heading,
          subHeading: p.subHeading,
        }));
      }
    }
  };
  if (!tabs.length || (tabs.length <= 1 && hideSingleTab)) return null;
  return (
    <div className="horizantal-tabs">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`horizantal-tab ${
            tab.id === activeTab.id ? 'active' : ''
          }`}
          onClick={() => activeTabHandler(tab)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
}

export default HorizantalTabs;
