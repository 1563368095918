import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createExperienceAPICall,
  experienceListAPICall,
  getExperiencesByLocationApiCall,
  updateEntitySurveyAssignment,
} from 'apis/cxmeter';
import { showErrorToast } from 'utils/helpers';

export const postExperience = createAsyncThunk(
  'experience/postExperience',
  async (data, { getState }) => {
    const { parentEntity } = getState().authentication ?? {};
    return createExperienceAPICall(
      data.exp.name,
      parentEntity.industry_type ?? data.industryType,
    ).catch(showErrorToast);
  },
);

export const updateFormtoExperience = createAsyncThunk(
  'experience/updateForm',
  async ({ locationId, data } = {}) =>
    updateEntitySurveyAssignment(locationId, data).catch(showErrorToast),
);

export const getExperiencesByLocation = createAsyncThunk(
  'experience/getExperiencesByLocation',
  async (params) =>
    getExperiencesByLocationApiCall(params).catch(showErrorToast),
);

export const getAllExperiences = createAsyncThunk(
  'experience/getAllExperiences',
  async (params) => experienceListAPICall(params).catch(showErrorToast),
);
