/* eslint-disable no-else-return */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';

import { parentEntityListAPICall } from 'apis/cxmeter';
import { PermissionDenied } from 'pages';
import {
  ENTITY_ADMIN,
  FINANCIAL_ADMIN,
  PARENT_ENTITY_ADMIN,
  URLS,
} from 'utils/constants';

export default function ProtectedRoute({
  Component,
  isSuperuser,
  isRestrictedToParentEntityAdmin,
  isRestrictedToEntityAdmin,
  isRestrictedToFinancialAdmin,
  ...rest
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, parentEntity, isAppLoading } = useSelector(
    (state) => state.authentication,
  );

  useEffect(() => {
    if (!isAppLoading) {
      if (!isEmpty(user) && !user.is_active) {
        navigate(URLS.VERIFY_EMAIL_URL);
      }
      if (
        !isEmpty(user) &&
        user.type !== ENTITY_ADMIN &&
        isEmpty(parentEntity)
      ) {
        parentEntityListAPICall().then((orgs) => {
          if (orgs.length === 0) {
            navigate(URLS.REGISTER_ORG_URL, { replace: true });
          } else if (orgs.length > 1) {
            navigate(URLS.ORGANIZATION_URL);
          }
        });
      }
    }
  }, [parentEntity, user, isAppLoading]);

  if (isAppLoading) {
    return null;
  }

  if (isSuperuser) {
    if (user.is_superuser) {
      return <Component {...rest} />;
    }
    return <PermissionDenied />;
  }

  if (!isEmpty(user)) {
    if (isRestrictedToParentEntityAdmin && user.type === PARENT_ENTITY_ADMIN) {
      return <Component {...rest} />;
    } else if (isRestrictedToEntityAdmin && user.type === ENTITY_ADMIN) {
      return <Component {...rest} />;
    } else if (isRestrictedToFinancialAdmin && user.type === FINANCIAL_ADMIN) {
      return <Component {...rest} />;
    } else {
      return <PermissionDenied />;
    }
  }

  if (isEmpty(user)) {
    return (
      <Navigate to={URLS.LOGIN_URL} state={{ redirectTo: location.pathname }} />
    );
  }
}
