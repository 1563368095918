/* eslint-disable no-underscore-dangle */

/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Tabs, Tooltip, Typography } from 'antd';

import { SurveyThumbnail } from 'components/surveyThumbnail';
import { Icon, Spinner } from 'components/ui';
import {
  getAllExperiences,
  getExperiencesByLocation,
  updateFormtoExperience,
} from 'redux/experience/experienceActions';
import { resetEntityLinkedExperiences } from 'redux/experience/experienceSlice';
import { getEntitySurveyById } from 'redux/surveys/surveysActions';
import { setSurveyDetail } from 'redux/surveys/surveysSlice';
import {
  CREATE_SURVEY_URL,
  CUSTOM_FEEDBACK_FORMS_SETTINGS_URL,
} from 'utils/constants/urlConstants';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

import LinkLocationExperienceModal from './LinkLocationExperienceModal';

import './_locationLinkedExperiences.scss';

const translationKey = 'settings.locations.linkedExperiences';

function LocationLinkedExperiences({
  isOnboarding,
  onChangeLinkedExperiences,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locationId } = useParams();
  const { state, pathname } = useLocation();
  const dispatch = useDispatchWithErrors();

  const {
    surveyDetail: { data: surveyPreviewData, loading: surveyPreviewLoading },
  } = useSelector((s) => s.surveys);
  const {
    entitiesLinkedExperiences: { data: entities, loading },
  } = useSelector((s) => s.experience);

  const entitiyLinkedExperiencesHash = useRef({});
  const selectedExperience = useRef(state?.selectedExperience);

  const [activeExperinceKey, setActiveExperinceKey] = useState();
  const [unlinkLoading, setUnlinkLoading] = useState(false);
  const [entitiyLinkedExperiences, setEntitiyLinkedExperiences] = useState([]);
  const [isOpenExperienceModal, setIsOpenExperienceModal] = useState(
    Boolean(state?.selectedExperience),
  );

  const handleOpenExperienceModal = (linkedExperience) => {
    selectedExperience.current = linkedExperience;
    setIsOpenExperienceModal(true);
  };

  const handleCloseExperienceModal = () => {
    selectedExperience.current = null;
    setIsOpenExperienceModal(false);
  };

  const navigateToFeedbackFormDetailPage = (
    surveyId,
    isTemplate,
    preSelectedExperience,
  ) => {
    const selectedExperienceData = selectedExperience.current;
    const locationState = {
      selectedExperience: selectedExperienceData,
      redirectParams: {
        next: pathname,
        preSelectedExperience:
          preSelectedExperience ?? selectedExperienceData?.experience,
        ...(isOnboarding
          ? {
              surveyId: surveyId === 'new' ? null : surveyId,
              isTemplate,
            }
          : {}),
      },
    };
    if (isOnboarding) {
      navigate(`${CREATE_SURVEY_URL}`, {
        state: locationState,
      });
    } else {
      navigate(`${CUSTOM_FEEDBACK_FORMS_SETTINGS_URL}/${surveyId}/`, {
        state: locationState,
      });
    }
  };

  const handleUnlinkExperience = async (unLinkedExperience) => {
    const linkedExperiencesList = [];
    entitiyLinkedExperiences.forEach(({ experience, id, survey_id }) => {
      if (id !== unLinkedExperience.id) {
        linkedExperiencesList.push({
          id,
          survey_id,
          experience: experience.id,
        });
      }
    });
    setUnlinkLoading(true);
    const { isError } = await dispatch(
      updateFormtoExperience({
        locationId,
        data: linkedExperiencesList,
      }),
    );
    if (!isError) {
      const { data } = await dispatch(
        getExperiencesByLocation({
          entity_id: locationId,
          is_onboarding: isOnboarding,
        }),
      );
      setActiveExperinceKey(`${data[0]?.entity_experiences?.[0]?.id}`);
    }
    setUnlinkLoading(false);
  };

  const handleSurveyPreview = (surveyId) => {
    if (surveyId !== surveyPreviewData?._id?.$oid && !surveyPreviewLoading)
      dispatch(getEntitySurveyById(surveyId));
  };

  const handleChangeExperienceTab = (experienceKey) => {
    setActiveExperinceKey(experienceKey);
    dispatch(setSurveyDetail(null));
  };

  useEffect(() => {
    const data = entities[0] ? entities[0].entity_experiences : [];
    setEntitiyLinkedExperiences(data);
    if (onChangeLinkedExperiences) onChangeLinkedExperiences(data);
    if (!activeExperinceKey && data[0]) {
      setActiveExperinceKey(`${data[0].id}`);
    }
    const tempHash = {};
    data.forEach((linkedExperience) => {
      tempHash[linkedExperience.experience.id] = linkedExperience;
    });
    entitiyLinkedExperiencesHash.current = tempHash;
  }, [entities]);

  useEffect(() => {
    if (surveyPreviewData) {
      dispatch(setSurveyDetail(null));
    }
  }, [activeExperinceKey]);

  useEffect(() => {
    dispatch(
      getExperiencesByLocation({
        entity_id: locationId,
        is_onboarding: isOnboarding,
      }),
    );
    dispatch(getAllExperiences());
    return () => {
      dispatch(resetEntityLinkedExperiences());
    };
  }, []);

  return (
    <div className="location-experiences-container">
      {loading || unlinkLoading ? <Spinner isTransparentBackground /> : null}
      <div className="location-experience-header">
        <h5>{t(`${translationKey}.title`)}</h5>
        <button
          className="cstm-btn secondary-cstm-btn"
          style={{ paddingBottom: 6 }}
          onClick={() => handleOpenExperienceModal(null)}
        >
          <Icon type="link" />
          <span>{t(`${translationKey}.linkNew`)}</span>
        </button>
      </div>
      <div className="linked-experiences-container">
        {!loading && !entitiyLinkedExperiences.length ? (
          <p>{t(`${translationKey}.notFound`)}</p>
        ) : (
          <Tabs
            defaultActiveKey="1"
            activeKey={activeExperinceKey}
            onChange={handleChangeExperienceTab}
            tabPosition="top"
            style={{ minHeight: 260 }}
            items={entitiyLinkedExperiences.map(
              ({ experience, ...linkedExperience }) => {
                return {
                  label: (
                    <Typography.Text
                      className="linked-experience-tab-title"
                      style={{ maxWidth: 250, color: 'inherit' }}
                      ellipsis={{
                        tooltip: { title: experience.name },
                      }}
                    >
                      {experience.name}
                    </Typography.Text>
                  ),
                  key: `${linkedExperience.id}`,
                  children: (
                    <div className="linked-experience-form-container">
                      <div className="linked-experience-form">
                        <SurveyThumbnail
                          onEdit={() =>
                            navigateToFeedbackFormDetailPage(
                              linkedExperience.survey_id,
                              linkedExperience.is_template,
                              experience,
                            )
                          }
                          data={{ ...linkedExperience }}
                        />
                        <div>
                          <p className="linked-experience-info">
                            <span className="experience-name">
                              {experience.name}{' '}
                            </span>
                            <span className="form-type">
                              (
                              {linkedExperience.is_template
                                ? 'CX METER'
                                : 'Custom'}{' '}
                              Form)
                            </span>
                          </p>
                          <p className="linked-experience-form-title">
                            {linkedExperience.title}
                          </p>
                        </div>
                      </div>
                      <div className="linked-experience-actions">
                        <Tooltip
                          title={
                            entitiyLinkedExperiences.length <= 1
                              ? t(`${translationKey}.requiredExperience`)
                              : ''
                          }
                        >
                          <button
                            disabled={entitiyLinkedExperiences.length <= 1}
                            onClick={() =>
                              handleUnlinkExperience(linkedExperience)
                            }
                            className="cstm-btn tertiary-cstm-btn"
                          >
                            {t(`${translationKey}.actions.unlink`)}
                          </button>
                        </Tooltip>
                        {isOnboarding ? (
                          <button
                            onClick={() =>
                              handleSurveyPreview(linkedExperience.survey_id)
                            }
                            className="cstm-btn tertiary-cstm-btn survey-preview"
                          >
                            <Icon
                              type={
                                surveyPreviewLoading ? 'sync' : 'visibility'
                              }
                            />
                            <span>
                              {t(`${translationKey}.actions.preview`)}
                            </span>
                          </button>
                        ) : null}
                        <button
                          onClick={() =>
                            handleOpenExperienceModal({
                              experience,
                              ...linkedExperience,
                            })
                          }
                          className="cstm-btn tertiary-cstm-btn"
                        >
                          <Icon type="edit" />
                          <span>{t(`${translationKey}.actions.manage`)}</span>
                        </button>
                      </div>
                    </div>
                  ),
                };
              },
            )}
          />
        )}
      </div>
      {isOpenExperienceModal ? (
        <LinkLocationExperienceModal
          isOpen
          navigateToFeedbackFormDetailPage={navigateToFeedbackFormDetailPage}
          entitiyLinkedExperiencesHash={entitiyLinkedExperiencesHash.current}
          entitiyLinkedExperiences={entitiyLinkedExperiences}
          linkedExperienceRef={selectedExperience}
          onClose={handleCloseExperienceModal}
          isOnboarding={isOnboarding}
          setActiveExperinceKey={setActiveExperinceKey}
        />
      ) : null}
    </div>
  );
}

export default LocationLinkedExperiences;
