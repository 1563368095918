import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Table } from 'antd';

import { SettingsPageContext } from 'contexts';
import {
  deleteEntitySurvey,
  duplicateSurvey,
} from 'redux/surveys/surveysActions';
import { PAGINATION_AND_SORTING_PARAMS } from 'utils/constants';
import {
  CUSTOM_FEEDBACK_FORMS_SETTINGS_URL,
  TEMPLATES_FEEDBACK_FORMS_SETTINGS_URL,
} from 'utils/constants/urlConstants';
import { getAntdPaginationProps } from 'utils/helpers';
import { useEffectAfterMount } from 'utils/hooks';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import usePopStateListener from 'utils/hooks/usePopStateListener';

import FeedbackFormsTableColumns from './FeedbackFormTableColumns';

function SurveyFormsListing({ isTemplateSurvey, action }) {
  const { id } = useParams();
  const historyChanged = usePopStateListener();
  const navigate = useNavigate();
  const dispatch = useDispatchWithErrors();
  const {
    [isTemplateSurvey ? 'templateSurveyForms' : 'customSurveyForms']: {
      data: surveyForms,
      loading,
    },
  } = useSelector((s) => s.surveys);

  const [isActionsLoading, setIsActionsLoading] = useState(false);

  const { params, setParams, onChangePagination } =
    useContext(SettingsPageContext);

  const handleDeleteSurvey = async (survey) => {
    setIsActionsLoading(true);
    const { isError } = await dispatch(deleteEntitySurvey(survey.survey_id));
    if (!isError) {
      toast.success('Survey deleted successfully');
    }
    await dispatch(action(params));
    setIsActionsLoading(false);
  };

  const handleDuplicateSurvey = async (survey) => {
    setIsActionsLoading(true);
    const { isError, data } = await dispatch(duplicateSurvey(survey.survey_id));
    if (!isError && data) {
      // eslint-disable-next-line no-underscore-dangle
      navigate(`${CUSTOM_FEEDBACK_FORMS_SETTINGS_URL}/${data?._id.$oid}`);
      setParams({ ...PAGINATION_AND_SORTING_PARAMS });
    }
    setIsActionsLoading(false);
  };

  const handleEditSurvey = (survey) => {
    const url = isTemplateSurvey
      ? TEMPLATES_FEEDBACK_FORMS_SETTINGS_URL
      : CUSTOM_FEEDBACK_FORMS_SETTINGS_URL;
    navigate(`${url}/${survey.survey_id}`);
  };

  useEffect(() => {
    if (!id) dispatch(action(params));
  }, [params, id]);

  useEffectAfterMount(() => {
    setParams({ ...PAGINATION_AND_SORTING_PARAMS });
  }, [historyChanged]);

  const isLoading = isActionsLoading || loading;

  return id ? (
    <Outlet />
  ) : (
    <div className="feedback-forms-listing-container">
      <Table
        className="left-align"
        loading={isLoading}
        columns={FeedbackFormsTableColumns(isTemplateSurvey, {
          onEdit: handleEditSurvey,
          onDelete: handleDeleteSurvey,
          onDuplicate: handleDuplicateSurvey,
        })}
        dataSource={surveyForms.results}
        scroll={{ x: true }}
        pagination={{
          ...getAntdPaginationProps({
            data: surveyForms,
            currentPage: params.page ?? 1,
            onChangePagination,
          }),
        }}
      />
    </div>
  );
}

export default SurveyFormsListing;
