import { useRef } from 'react';

import { DatePicker, Switch, TimePicker } from 'antd';
import dayjs from 'dayjs';

import { Select } from 'components/ui';
import {
  DATE_FORMAT,
  DAYS_OPTIONS,
  REPEAT_MONTHLY,
  REPEAT_OPTIONS,
  TIME_FORMAT,
} from 'utils/constants/manageEmails';

function EmailSchedule({ formik }) {
  const { errors, values, touched, setFieldValue } = formik;
  const emailScheduleInputsRef = useRef();
  const disabledDays = useRef({});
  const reminderSchedule = values.reminder_schedules;

  const onRepeatChange = (repeatType) => {
    const baseDate = values.scheduled_at?.date
      ? dayjs(values.scheduled_at.date)
      : dayjs();
    const endDate = baseDate
      .add(repeatType === REPEAT_MONTHLY ? 3 : 1, 'months')
      .format(DATE_FORMAT);
    setFieldValue('reminder_schedules', {
      ...reminderSchedule,
      reminder_type: repeatType,
      ends_at: endDate,
    });
  };

  const onDaysChange = (selectedDays) => {
    const days = {};
    selectedDays.forEach((d) => {
      const dayIndex = DAYS_OPTIONS.findIndex((day) => day.value === d);
      if (dayIndex > -1) {
        days[DAYS_OPTIONS[(dayIndex - 2 + 7) % 7].value] = true;
        days[DAYS_OPTIONS[(dayIndex - 1 + 7) % 7].value] = true;
        days[DAYS_OPTIONS[(dayIndex + 1) % 7].value] = true;
        days[DAYS_OPTIONS[(dayIndex + 2) % 7].value] = true;
      }
    });
    disabledDays.current = { ...days };
    setFieldValue('reminder_schedules.custom_reminder_day', selectedDays);
  };
  const onTimeChange = (time) => {
    setFieldValue('reminder_schedules.reminder_time', time);
  };

  const getIsToday = () =>
    dayjs(values.scheduled_at.date).isSame(dayjs(), 'day');
  const getNextHour = () => dayjs().add(1, 'hour').add(5, 'minute');

  const disableDates = (current) => {
    const nextHourWithExtraMinutes = getNextHour();
    const isTimeLessThanOneHour = dayjs(values.scheduled_at.time).isBefore(
      nextHourWithExtraMinutes,
    );
    if (isTimeLessThanOneHour) {
      return current && current < dayjs().endOf('day');
    }
    return current && current.isBefore(dayjs().startOf('day'));
  };

  const disabledDatesForEndDate = (current) => {
    const scheduledAtDate = values.scheduled_at.date;
    const oneYearFromScheduledAt = dayjs(scheduledAtDate).add(1, 'year');

    return (
      (current && current.isBefore(dayjs(scheduledAtDate), 'day')) ||
      current.isSame(dayjs(scheduledAtDate), 'day') ||
      current.isAfter(oneYearFromScheduledAt, 'day')
    );
  };

  const disabledTime = () => {
    const nextHourWithExtraMinutes = getNextHour();
    const isToday = getIsToday();
    if (isToday) {
      return {
        disabledHours: () => [...Array(nextHourWithExtraMinutes.hour()).keys()],
        disabledMinutes: (selectedHour) => {
          if (selectedHour === nextHourWithExtraMinutes.hour()) {
            return [...Array(nextHourWithExtraMinutes.minute()).keys()];
          }
          return [];
        },
      };
    }
    return {};
  };

  return (
    <div className="email-schedule-container" ref={emailScheduleInputsRef}>
      <div className="schedule-label">
        <Switch
          name="show_email_schedule"
          className="primary-antd-switch-input"
          onChange={(checked) => setFieldValue('show_email_schedule', checked)}
          checked={values.show_email_schedule}
        />
        <p>Schedule Email</p>
      </div>
      {values.show_email_schedule ? (
        <div>
          <div className="schedule-params-container">
            <div className="schedule-email-date">
              <p className="m-0">Date</p>
              <DatePicker
                inputReadOnly
                disabledDate={disableDates}
                format="DD MMM YYYY"
                className="date-time-picker-input"
                value={values.scheduled_at.date}
                getPopupContainer={() => emailScheduleInputsRef.current}
                onChange={(date) => setFieldValue('scheduled_at.date', date)}
              />
              {touched.scheduled_at?.date && (
                <span className="control-error">
                  {errors.scheduled_at?.date}
                </span>
              )}
            </div>
            <div className="time-dropdown">
              <p className="m-0">Time</p>
              <TimePicker
                showNow={!getIsToday()}
                defaultOpenValue={
                  getIsToday()
                    ? values.scheduled_at.time ?? getNextHour()
                    : null
                }
                className="date-time-picker-input"
                placement="topLeft"
                use12Hours
                onsc
                value={values.scheduled_at.time}
                format="h:mm A"
                inputReadOnly
                disabledTime={disabledTime}
                onChange={(time) => setFieldValue('scheduled_at.time', time)}
                getPopupContainer={() => emailScheduleInputsRef.current}
              />
              {touched.scheduled_at?.time && (
                <span className="control-error">
                  {errors.scheduled_at?.time}
                </span>
              )}
            </div>
          </div>
          {formik.submitCount > 0 &&
            errors.scheduled_at &&
            typeof errors.scheduled_at === 'string' && (
              <span className="control-error">{errors.scheduled_at}</span>
            )}
        </div>
      ) : null}
      <div className="schedule-label">
        <Switch
          name="show_reminder_schedule"
          className="primary-antd-switch-input"
          onChange={(checked) =>
            setFieldValue('show_reminder_schedule', checked)
          }
          checked={values.show_reminder_schedule}
        />
        <p>Schedule Reminder</p>
      </div>
      {values.show_reminder_schedule ? (
        <>
          <div className="schedule-params-container">
            <div className="repeat-dropdown">
              <p className="m-0">Repeat</p>
              <Select
                hideTooltip
                contentClassName="align-top"
                name="reminder_type"
                placeholder="Select"
                options={REPEAT_OPTIONS}
                onChange={onRepeatChange}
                value={reminderSchedule.reminder_type}
                chevronStyles={{ color: '#de0024' }}
              />
              {touched.reminder_schedules?.reminder_type && (
                <span className="control-error">
                  {errors.reminder_schedules?.reminder_type}
                </span>
              )}
            </div>
            {reminderSchedule?.reminder_type === 'Custom' ? (
              <div className="days-dropdown">
                <p className="m-0">Repeat on (Days)</p>
                <Select
                  hideTooltip
                  isMultiSelect
                  contentClassName="align-top"
                  name="reminder_type"
                  placeholder="Select"
                  options={DAYS_OPTIONS}
                  onChange={onDaysChange}
                  value={reminderSchedule.custom_reminder_day}
                  chevronStyles={{ color: '#de0024' }}
                  disabledItems={disabledDays.current}
                />
                {touched.reminder_schedules?.custom_reminder_day && (
                  <span className="control-error">
                    {errors.reminder_schedules?.custom_reminder_day}
                  </span>
                )}
              </div>
            ) : null}
            <div className="time-dropdown">
              <p className="m-0">Time</p>
              <TimePicker
                className="date-time-picker-input"
                placement="topLeft"
                use12Hours
                value={
                  reminderSchedule.reminder_time
                    ? dayjs(reminderSchedule.reminder_time, TIME_FORMAT)
                    : null
                }
                format="h:mm A"
                onChange={onTimeChange}
                getPopupContainer={() => emailScheduleInputsRef.current}
              />
              {touched.reminder_schedules?.reminder_time && (
                <span className="control-error">
                  {errors.reminder_schedules?.reminder_time}
                </span>
              )}
            </div>
          </div>
          <div className="schedule-email-date">
            <p className="m-0">End Date</p>
            <DatePicker
              inputReadOnly
              disabledDate={disabledDatesForEndDate}
              format="DD MMM YYYY"
              className="date-time-picker-input"
              value={
                reminderSchedule?.ends_at
                  ? dayjs(reminderSchedule?.ends_at)
                  : null
              }
              getPopupContainer={() => emailScheduleInputsRef.current}
              onChange={(date) =>
                setFieldValue('reminder_schedules.ends_at', date)
              }
            />
            {touched.reminder_schedules?.ends_at && (
              <span className="control-error">
                {errors.reminder_schedules?.ends_at}
              </span>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default EmailSchedule;
