/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { RichTextEditor } from 'components/richTextEditor';
import { Switch } from 'components/switch';
import { HAPPY, NEUTRAL, UNHAPPY } from 'utils/constants';
import { QUESTION_TYPES } from 'utils/constants/questionTypes';

import './_surveyNpsSection.scss';

export default function SurveyNpsSection({
  npsSection,
  sectionErrors,
  selectedSection,
  setSortedQuestions,
  setSelectedSection,
  formik,
}) {
  const { t } = useTranslation();
  const collectNPSScore = formik.values.is_collecting_nps_response;
  const showFullSurvey = formik.values.is_full_form_enabled;
  // 0 is nps section index
  const npsSectionId = 0;
  const npsQuestion = npsSection?.questions[0];
  const npsQuestionError = sectionErrors?.[0]?.questions?.[0];

  const handleSetSelectedSection = () => {
    setSelectedSection(npsSectionId);
  };

  const handleSetQuestionLabel = (label) => {
    setSortedQuestions(npsSectionId, [{ ...npsQuestion, label }]);
  };

  const handleSurveyConfig = ({ target: { name, checked } }) => {
    formik.setFieldValue(name, checked);
  };

  const handleSetQuestionStatus = () => {
    setSortedQuestions(npsSectionId, [
      { ...npsQuestion, is_active: !npsQuestion.is_active },
    ]);
  };

  useEffect(() => {
    if (!npsQuestion.is_active) {
      formik.setValues({
        ...formik.values,
        is_collecting_nps_response: false,
        is_full_form_enabled: false,
      });
    }
  }, [npsQuestion.is_active]);

  return (
    <div className="sectionWrapper-nps">
      <section
        className={`surveyBuilderSection ${
          selectedSection === npsSectionId && 'selectedSurveyBuilderSection'
        } ${npsQuestionError ? 'invalidSurveyBuilderSection' : ''}`}
        tabIndex={0}
        role="button"
        onClick={handleSetSelectedSection}
      >
        <div className="surveyBuilderQuestionWrapper">
          <div className="surveyBuilderQuestionRow">
            <div className="survey-builder__question-row-question">
              <div
                className={`editor-container ${
                  npsQuestion.is_active ? '' : 'is-disabled'
                }`}
                style={{ flexGrow: 1 }}
              >
                <RichTextEditor
                  readOnly={!npsQuestion.is_active}
                  placeholder="Type your question here"
                  setState={handleSetQuestionLabel}
                  content={npsQuestion.label}
                  error={npsQuestionError?.label?.error}
                  pasteFormatted
                />
              </div>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="danger" disabled>
                {QUESTION_TYPES[npsQuestion.question_type].label}
              </Dropdown.Toggle>
            </Dropdown>
          </div>
          <div className="surveyPreviewQuestionWrapper">
            <div className="experienceIcons">
              <img src="/assets/icons/icon_happy.svg" alt={HAPPY} />
              <img src="/assets/icons/icon_neutral.svg" alt={NEUTRAL} />
              <img src="/assets/icons/icon_unhappy.svg" alt={UNHAPPY} />
            </div>
            <div className="compulsory-rating">
              <p>{npsQuestion.is_active ? 'Enabled' : 'Disabled'}</p>
              <Switch
                onChange={handleSetQuestionStatus}
                checked={npsQuestion.is_active}
              />
            </div>
          </div>
          <div
            className={`questionFooter ${
              npsQuestion.is_active ? '' : 'is-disabled'
            }`}
          >
            <div className="nps-configs">
              <div className="config-container">
                <h6>{t('formBuilder.configuration.instantFeedback.title')}</h6>
                <p>{t('formBuilder.configuration.instantFeedback.subTitle')}</p>
              </div>
              <div>
                <Switch
                  name="is_collecting_nps_response"
                  onChange={handleSurveyConfig}
                  disabled={!npsQuestion.is_active}
                  checked={collectNPSScore}
                />
              </div>
            </div>
            <div className="nps-configs">
              <div className="config-container">
                <h6>{t('formBuilder.configuration.showFullSurvey.title')}</h6>
                <p>{t('formBuilder.configuration.showFullSurvey.subTitle')}</p>
              </div>
              <div>
                <Switch
                  name="is_full_form_enabled"
                  onChange={handleSurveyConfig}
                  disabled={!npsQuestion.is_active}
                  checked={showFullSurvey}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
