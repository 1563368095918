import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

import { chartHelper } from 'utils/helpers';

import colors from 'scss/_customVariables.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart({ statistics }) {
  let happyPercent = 0;
  let unhappyPercent = 0;
  let neutralPercent = 0;
  if (statistics.total_feedbacks > 0) {
    happyPercent = Math.round(
      (statistics.total_happy_feedbacks / statistics.total_feedbacks) * 100,
    );
    unhappyPercent = Math.round(
      (statistics.total_unhappy_feedbacks / statistics.total_feedbacks) * 100,
    );
    neutralPercent = Math.round(
      (statistics.total_neutral_feedbacks / statistics.total_feedbacks) * 100,
    );
  }

  const data = {
    labels: [
      ['Happy', `${happyPercent}% (${statistics.total_happy_feedbacks})`],
      ['Neutral', `${neutralPercent}% (${statistics.total_neutral_feedbacks})`],
      ['Unhappy', `${unhappyPercent}% (${statistics.total_unhappy_feedbacks})`],
    ],
    datasets: [
      {
        label: 'NPS',
        data: [happyPercent, neutralPercent, unhappyPercent],
        backgroundColor: [colors.primary, colors.secondary, colors.danger],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    cutout: 150,
    plugins: {
      tooltip: {
        enabled: false,
        external: chartHelper.externalTooltipHandlerDoughnutChart,
      },
      legend: {
        padding: 40,
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxHeight: 5,
          boxWidth: 5,
          padding: 40,
          font: {
            size: 22,
          },
        },
        maxHeight: 400,
      },
    },
  };

  const plugins = [
    {
      beforeDraw(chart) {
        const { width } = chart;
        const { height } = chart;
        const { ctx } = chart;
        ctx.restore();
        ctx.font = `${7}em sans-serif`;
        ctx.textBaseline = 'top';
        let textSpace = 5;
        if (statistics.happiness_index < 10) textSpace = 3.45;
        else if (statistics.happiness_index < 100) textSpace = 4;
        let text = statistics.happiness_index;
        let textX = Math.round(
          (width - ctx.measureText(text).width) / textSpace,
        );
        let textY = height / 2.3;
        ctx.fillText(text, textX, textY);
        ctx.save();
        ctx.font = `${2}em sans-serif`;
        text = 'Happiness (NPS) Index';
        textX = Math.round((width - ctx.measureText(text).width) / 5.4);
        textY = height / 2.6;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return statistics.happiness_index !== undefined ? (
    <Doughnut data={data} options={options} plugins={plugins} />
  ) : null;
}
