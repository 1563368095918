import React from 'react';
import { Outlet } from 'react-router-dom';

import { Loader } from 'components';
import DesktopSignupWarning from 'components/ui/modal/SignupDesktopModal';
import { useOnboardingRedirect } from 'utils/hooks';

export default function OnboardingNavigator() {
  const { isLoading } = useOnboardingRedirect();

  return (
    <>
      <DesktopSignupWarning hideWarning={isLoading} />
      {isLoading ? <Loader /> : <Outlet />}
    </>
  );
}
