import { useTranslation } from 'react-i18next';

import { RichTextEditor } from 'components/richTextEditor';
import { Switch } from 'components/switch';
import { copyContentStateWithPrefix } from 'utils/helpers/richTextEditorHelpers';

import './_surveyWelcomeText.scss';

const welcomeTextTranslation = 'formBuilder.welcomeText';

function SurveyWelcomeText({ formik }) {
  const { t } = useTranslation();
  const { errors, values } = formik;
  const invalidWelcomeText = Boolean(errors.welcome_text);
  const welcomeText = values.welcome_text;

  const handleWelcomeTextLabel = (welcomeTextLabel) => {
    formik.setFieldValue('welcome_text', welcomeTextLabel);
  };

  const handleWelcomeTextStatus = () => {
    formik.setFieldValue(
      'welcome_text',
      welcomeText === null ? copyContentStateWithPrefix('') : null,
    );
  };

  return (
    <div className="sectionWrapper-nps">
      <section
        className={`surveyBuilderSection surveyWelcomeTextSection ${
          invalidWelcomeText ? 'invalidWelcomeText' : ''
        }`}
        tabIndex={0}
        role="button"
      >
        <div className="surveyWelcomeTextHeader">
          <h6>{t(`${welcomeTextTranslation}.title`)}</h6>
          <Switch
            name="welcome_text"
            onChange={handleWelcomeTextStatus}
            checked={welcomeText !== null}
          />
        </div>
        <div
          className={`editor-container ${
            welcomeText === null ? 'inactive-welcome-text' : ''
          }`}
        >
          <RichTextEditor
            readOnly={welcomeText === null}
            placeholder={t(`${welcomeTextTranslation}.placeholder`)}
            setState={handleWelcomeTextLabel}
            content={welcomeText ?? ''}
            pasteFormatted
          />
        </div>
        <span className="welcome-text-characters-limit">
          {t(`${welcomeTextTranslation}.maxCharacters`)}
        </span>
      </section>
    </div>
  );
}

export default SurveyWelcomeText;
