/* eslint-disable no-return-await */
import {
  ACTIVATION_API,
  CHANGE_PASSWORD_API,
  FORGOT_PASSWORD_API,
  LOGIN_API_V2,
  LOGIN_WITH_GOOGLE_API,
  RESET_PASSWORD_API,
  SIGNUP_API_V2,
  USER_API_V2,
} from './urls';
import { getAPICall, postAPICall, putAPICall } from './utils';

export async function loginAPICall(data) {
  return await postAPICall(LOGIN_API_V2, data, {}, false);
}

export async function signupAPICall(data) {
  return await postAPICall(SIGNUP_API_V2, data, {}, false);
}

export async function getUserAPICall(data) {
  return await getAPICall(USER_API_V2(data));
}

export async function editUserAPICall(data) {
  return await putAPICall(
    USER_API_V2(data.id),
    data.formData,
    {},
    true,
    'multipart/form-data',
  );
}

export async function loginWithGoogleAPICall(data) {
  return await postAPICall(LOGIN_WITH_GOOGLE_API, data, {}, false);
}

export async function sendForgotPasswordEmailAPICall(email) {
  return await postAPICall(FORGOT_PASSWORD_API, { email }, {}, false);
}

export async function changePasswordAPICall(userId, data) {
  return await putAPICall(CHANGE_PASSWORD_API(userId), data);
}

export async function resetPasswordAPICall({
  email,
  token,
  password,
  confirmPassword,
}) {
  return await postAPICall(
    `${RESET_PASSWORD_API}${email}/${token}`,
    { password, confirm_password: confirmPassword },
    {},
    false,
  );
}

export async function activateAccountAPICall({ email, token }) {
  return await getAPICall(`${ACTIVATION_API}${email}/${token}`, {}, false);
}
