import { createSlice } from '@reduxjs/toolkit';

import {
  getAllExperiences,
  getExperiencesByLocation,
} from './experienceActions';

const INITIAL_STATE = {
  entitiesLinkedExperiences: {
    data: [],
    loading: false,
  },
  allExperiences: {
    data: [],
    loading: false,
  },
};

const experienceSlice = createSlice({
  name: 'experienceSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetExperienceSlice: () => INITIAL_STATE,
    resetEntityLinkedExperiences: (state, action) => {
      state.entitiesLinkedExperiences = action.payload ?? {
        data: [],
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExperiencesByLocation.pending, (state) => {
      state.entitiesLinkedExperiences.loading = true;
    });
    builder.addCase(getExperiencesByLocation.rejected, (state) => {
      state.entitiesLinkedExperiences.loading = false;
    });
    builder.addCase(getExperiencesByLocation.fulfilled, (state, action) => {
      state.entitiesLinkedExperiences.loading = false;
      state.entitiesLinkedExperiences.data = action.payload;
    });

    builder.addCase(getAllExperiences.pending, (state) => {
      state.allExperiences.loading = true;
    });
    builder.addCase(getAllExperiences.rejected, (state) => {
      state.allExperiences.loading = false;
    });
    builder.addCase(getAllExperiences.fulfilled, (state, action) => {
      state.allExperiences.loading = false;
      state.allExperiences.data = action.payload;
    });

    return builder;
  },
});

export const { resetExperienceSlice, resetEntityLinkedExperiences } =
  experienceSlice.actions;
export default experienceSlice;
