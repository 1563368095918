import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { isEmpty } from 'lodash';

import {
  getSurveyAPICall,
  surveyCreateAPICall,
  surveyTemplateCreateAPICall,
  surveyUpdateAPICall,
} from 'apis/survey';
import {
  Loader,
  SurveyBuilder,
  SurveyCustomerDetail,
  SurveyPreview,
} from 'components';
import SimilarityConfirmModal from 'components/surveyBuilder/surveyBuilderAnswers/SimilarityConfirmModal';
import { OnboardingBranchContext } from 'contexts';
import { OnboardingLayout } from 'layouts';
import {
  BRANCH_SETUP_STEP,
  INITIAL_SURVEY,
  PARENT_ENTITY_ADMIN,
  URLS,
} from 'utils/constants';
import { showErrorToast, surveyHelper } from 'utils/helpers';
import { validateSurvey } from 'utils/helpers/surveyHelpers';
import { useDetectMobileScreen, useScreenshot } from 'utils/hooks';
import surveySchema from 'utils/validations/surveyValidations';

import './_createSurvey.scss';

export default function CreateSurvey() {
  const { state } = useLocation();
  const { setBranch } = useContext(OnboardingBranchContext);

  const navigate = useNavigate();
  const duplicateOptionsSections = useRef({});
  const isMobileDevice = useDetectMobileScreen();
  // eslint-disable-next-line no-unused-vars
  const [image, takeScreenshot, { error }] = useScreenshot();

  const {
    redirectParams: {
      next,
      preSelectedExperience: experience,
      surveyId,
      isTemplate,
    } = {},
  } = state ?? {};

  const formik = useFormik({
    initialValues: { ...INITIAL_SURVEY, experience: experience?.id },
    validationSchema: surveySchema,
  });
  const survey = formik.values;
  const [isPreviewOpen, setIsPreviewOpen] = useState(!isMobileDevice);
  const [loading, setLoading] = useState(Boolean(surveyId));
  const [isOpenSimilarityModal, setIsOpenSimilarityModal] = useState(false);
  const [isSimilarErrorActive, setIsSimilarErrorActive] = useState(false);
  const { user } = useSelector((s) => s.authentication);
  const ref = useRef(null);
  const { cleanSurvey, convertToRawSurvey } = surveyHelper;

  const getSurvey = async () => {
    const surveyById = await getSurveyAPICall(surveyId);
    formik.resetForm({
      values: convertToRawSurvey(surveyById),
    });
    setLoading(false);
  };

  const handleSurveyTitleChange = (event) => {
    formik.setFieldValue('title', event.target.value);
  };

  const checkSurveyValidity = async () => {
    const surveyErrors = await formik.validateForm();
    if (!isEmpty(surveyErrors) || !isEmpty(duplicateOptionsSections.current)) {
      return false;
    }
    return validateSurvey(survey);
  };

  const saveSurvey = async () => {
    const isSurveyValid = await checkSurveyValidity();
    if (!isSurveyValid) return;

    setIsOpenSimilarityModal(false);
    setLoading(true);
    const cleanedSurvey = cleanSurvey(survey, surveyId && !isTemplate);
    const preview = await takeScreenshot(ref.current);

    try {
      const surveyData = {
        experience: experience?.id,
        survey: { ...cleanedSurvey, preview },
      };
      let surveyRes = {};
      if (user.is_superuser) {
        surveyRes = await surveyTemplateCreateAPICall(surveyData);
        navigate(URLS.DASHBOARD_URL);
      } else if (user.type === PARENT_ENTITY_ADMIN) {
        if (surveyId && !isTemplate) {
          surveyRes = await surveyUpdateAPICall(surveyId, {
            ...cleanedSurvey,
            preview,
          });
          setBranch((branch) => {
            return {
              ...branch,
              experiences: {
                ...branch.experiences,
                [experience.id]: surveyId,
              },
            };
          });
        } else {
          surveyRes = await surveyCreateAPICall(surveyData);
          setBranch((branch) => {
            return {
              ...branch,
              experiences: {
                ...branch.experiences,
                [experience.id]: surveyRes?.id,
              },
            };
          });
        }
        if (next) {
          navigate(next, {
            state,
          });
        } else navigate(URLS.ADD_CUSTOMER_FEEDBACK_FORM_URL);
      }
    } catch (e) {
      showErrorToast(e);
    }
    setLoading(false);
  };

  const checkSimilarityError = async () => {
    const isSurveyValid = await checkSurveyValidity();
    if (!isSurveyValid) {
      return;
    }
    if (isSimilarErrorActive) {
      setIsOpenSimilarityModal(true);
    } else {
      saveSurvey();
    }
  };

  const handleSetPreview = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };

  useEffect(() => {
    if (surveyId) getSurvey();
    if (!experience) navigate(URLS.CREATE_SURVEY_TEMPLATE_URL, { state });
  }, [state]);

  if (loading) return <Loader />;

  return (
    <>
      {isMobileDevice && (
        <button className="button button-secondary" onClick={handleSetPreview}>
          {isPreviewOpen ? 'Go to Editing' : 'Go to Preview'}
        </button>
      )}
      {((isMobileDevice && !isPreviewOpen) || !isMobileDevice) && (
        <OnboardingLayout>
          <OnboardingLayout.Main>
            <OnboardingLayout.Header step={BRANCH_SETUP_STEP} />
            <OnboardingLayout.Form>
              <div className="survey-configurations">
                <label className="control">
                  <input
                    type="text"
                    value={survey.title}
                    onChange={handleSurveyTitleChange}
                    className="surveyFormTitle"
                    placeholder="Survey Title"
                  />
                  {formik.errors.title ? (
                    <span
                      style={{ display: 'block' }}
                      className="control-error"
                    >
                      {formik.errors.title}
                    </span>
                  ) : null}
                </label>
              </div>
              <SurveyBuilder
                surveySections={survey.sections}
                setIsSimilarErrorActive={setIsSimilarErrorActive}
                formik={formik}
                duplicateOptionsSections={duplicateOptionsSections}
              />
              <SurveyCustomerDetail formik={formik} />
            </OnboardingLayout.Form>
            <OnboardingLayout.Footer
              continueText="Save Survey Form"
              onClickContinue={checkSimilarityError}
              onClickBack={() => navigate(-1, { state })}
            />
          </OnboardingLayout.Main>
          <OnboardingLayout.Sidepane>
            {((isMobileDevice && isPreviewOpen) || !isMobileDevice) && (
              <SurveyPreview survey={survey} innerRef={ref} />
            )}
          </OnboardingLayout.Sidepane>
          <SimilarityConfirmModal
            onClose={() => setIsOpenSimilarityModal(false)}
            isOpenModal={isOpenSimilarityModal}
            primaryAction={saveSurvey}
            secondaryAction={() => setIsOpenSimilarityModal(false)}
          />
        </OnboardingLayout>
      )}
    </>
  );
}
