import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SettingsPageContext } from 'contexts';
import SettingsPageContextProvider from 'layouts/settingsPageLayout/SettingsPageContextProvider';
import SettingsPageLayout from 'layouts/settingsPageLayout/SettingsPageLayout';
import { FeedbackFormSurveyDetail } from 'pages';
import {
  getCustomSurveyForms,
  getTemplateSurveyForms,
} from 'redux/surveys/surveysActions';
import { URLS } from 'utils/constants';
import {
  CUSTOM_FEEDBACK_FORMS_SETTINGS_URL,
  TEMPLATES_FEEDBACK_FORMS_SETTINGS_URL,
} from 'utils/constants/urlConstants';

import SurveyFormsListing from './SurveyFormsListing';

import './_FeedbackFormsList.scss';

const CUSTOM_FORMS = 'custom-forms';
const TEMPLATES_FORMS = 'template-forms';

const FEEDBACK_FORMS_DETAIL_TAB = [
  {
    id: 'survey-detail',
    name: 'Survey Detail',
    hideSearchFilter: true,
    isSubTab: true,
  },
];

export const FEEDBACK_FORMS_TABS = [
  {
    id: CUSTOM_FORMS,
    route: CUSTOM_FEEDBACK_FORMS_SETTINGS_URL,
    name: 'Custom Forms',
    isActiveSubTab: false,
    subTabs: FEEDBACK_FORMS_DETAIL_TAB,
  },
  {
    id: TEMPLATES_FORMS,
    route: TEMPLATES_FEEDBACK_FORMS_SETTINGS_URL,
    name: 'CXMeter Templates',
    isActiveSubTab: false,
    subTabs: FEEDBACK_FORMS_DETAIL_TAB,
  },
];

function FeedbackForms() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeTab } = useContext(SettingsPageContext);

  const handleAddNewSurvey = () => {
    navigate(`${activeTab.route ?? CUSTOM_FEEDBACK_FORMS_SETTINGS_URL}/new`);
  };

  return (
    <SettingsPageLayout
      pageTitle={t('settings.feedbackForms.title')}
      pageSubTitle={t('settings.feedbackForms.subTitle')}
      tabs={FEEDBACK_FORMS_TABS}
    >
      {{
        generalAction: activeTab?.isActiveSubTab ? null : (
          <div className="controls">
            <button
              className="add-new-survey px-4 cstm-btn primary-cstm-btn"
              size="large"
              onClick={handleAddNewSurvey}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span>{t('settings.feedbackForms.createNewForm')}</span>
            </button>
          </div>
        ),
        currentTabContent: (
          <Routes>
            <Route
              index
              element={<Navigate to={URLS.CUSTOM_FEEDBACK_FORMS_URL} replace />}
            />
            <Route
              path={URLS.CUSTOM_FEEDBACK_FORMS_URL}
              element={<SurveyFormsListing action={getCustomSurveyForms} />}
            >
              <Route path=":id" element={<FeedbackFormSurveyDetail />} />
            </Route>
            <Route
              path={URLS.TEMPLATES_FEEDBACK_FORMS_URL}
              element={
                <SurveyFormsListing
                  isTemplateSurvey
                  action={getTemplateSurveyForms}
                />
              }
            >
              <Route path=":id" element={<FeedbackFormSurveyDetail />} />
            </Route>
            <Route
              path="*"
              element={<Navigate to={URLS.CUSTOM_FEEDBACK_FORMS_URL} replace />}
            />
          </Routes>
        ),
      }}
    </SettingsPageLayout>
  );
}

function ManageFeedbackForms() {
  return (
    <SettingsPageContextProvider tabs={FEEDBACK_FORMS_TABS}>
      <FeedbackForms />
    </SettingsPageContextProvider>
  );
}

export default ManageFeedbackForms;
