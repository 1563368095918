import { useEffect, useState } from 'react';

import WarningModal from './WarningModal';

const { useDetectMobileScreen } = require('utils/hooks');

function DesktopSignupWarning({ pageName = 'sing up', hideWarning }) {
  const isMobile = useDetectMobileScreen();
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(true);

  useEffect(() => {
    if (isMobile) {
      setIsOpenWarningModal(true);
    }
  }, [isMobile]);

  return isMobile && isOpenWarningModal && !hideWarning ? (
    <WarningModal
      wrapperStyles={{ zIndex: 11111 }}
      title="Warning"
      subTitle={`For the best experience, please ${pageName} using a Desktop or PC for better user experience!`}
      primaryAction={() => setIsOpenWarningModal(false)}
      primaryActionTitle="OK"
    />
  ) : null;
}

export default DesktopSignupWarning;
