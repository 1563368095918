import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HelpForm } from 'components/header/components/help/Help';
import { Modal } from 'components/ui';

import './_modals.scss';

const translationKey = 'settings.locations.serviceRequest.requestInitials';

function ServiceRequestModal({ onClose }) {
  const { t } = useTranslation();
  const requestFormRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const handleModalClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleSubmitRequest = async () => {
    const { submitForm } = requestFormRef.current ?? {};
    if (submitForm) {
      setIsLoading(true);
      const { isError } = await submitForm();
      if (!isError) {
        onClose();
      }
      setIsLoading(false);
    }
  };

  return (
    <Modal className="service-request-modal" open onClose={handleModalClose}>
      <Modal.Header
        title={t('navigation.helpPopup.title')}
        onClose={handleModalClose}
      />
      <Modal.Body>
        <HelpForm
          ref={requestFormRef}
          hideTitle
          defaultValues={{
            subject: t(`${translationKey}.title`),
            description: t(`${translationKey}.subTitle`),
          }}
        />
      </Modal.Body>
      <Modal.Footer
        primaryAction={handleSubmitRequest}
        primaryLabel="Submit"
        disablePrimaryAction={isLoading}
        isLoading={isLoading}
        secondaryAction={handleModalClose}
        disableSecondaryAction={isLoading}
      />
    </Modal>
  );
}

export default ServiceRequestModal;
