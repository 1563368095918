import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { isEmpty } from 'lodash';

import { GoogleReviewsLocationForm } from 'components/forms';
import { useLocationSetup } from 'components/hooks';
import NewLocationCostModal from 'components/modals/NewLocationCostModal';
import { SettingsPageContext } from 'contexts';
import { getPaginatedLocations } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { resetLocationDetail } from 'redux/locationsAndRegions/locationsAndRegionsSlice';
import { MANAGE_LOCATIONS_SETTINGS_URL } from 'utils/constants/urlConstants';

export default function LocationInfoForm() {
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const navigate = useNavigate();
  const { params } = useContext(SettingsPageContext);
  const [isOpenPriceWarningModal, setIsOpenPriceWarningModal] = useState(false);

  const { isLoading, isSubmitting, formik } = useLocationSetup({
    locationId: locationId === 'new' ? undefined : locationId,
    location: null,
    onUpdate: (data) => {
      dispatch(resetLocationDetail(data));
      dispatch(getPaginatedLocations(params));
      navigate(`${MANAGE_LOCATIONS_SETTINGS_URL}/${data.id}`);
    },
  });

  const handleCreateNewLocation = async () => {
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      setIsOpenPriceWarningModal(true);
    } else {
      const allTouched = Object.keys(formik.values).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      formik.setTouched(allTouched);
    }
  };

  return (
    <div className="location-info-container">
      <GoogleReviewsLocationForm formik={formik} />
      <div>
        <button
          aria-busy={isSubmitting || isLoading ? true : null}
          type="button"
          className="button button-primary"
          onClick={
            locationId === 'new' ? handleCreateNewLocation : formik.submitForm
          }
          disabled={!formik.dirty}
        >
          Save
        </button>
      </div>
      {isOpenPriceWarningModal && locationId === 'new' ? (
        <NewLocationCostModal
          isOpenPriceWarningModal={isOpenPriceWarningModal}
          onClose={() => setIsOpenPriceWarningModal(false)}
          onCreateNewLocation={formik.submitForm}
        />
      ) : null}
    </div>
  );
}
