import { useCallback, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import _ from 'lodash';

import { loginAPICall } from 'apis/authentication';
import { singleParentEntitySelectAPICall } from 'apis/cxmeter';
import { getUserAndInitialiseData } from 'redux/authentication/authenticationActions';
import {
  ENTITY_ADMIN,
  FORBIDDEN_CODE,
  PARENT_ENTITY_ADMIN,
  URLS,
} from 'utils/constants';
import { storeParentEntity, storeTokens, storeUser } from 'utils/helpers';

export default function useLogin(callback, deps) {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const login = useCallback(async (data, userInfo = null) => {
    try {
      setIsLoggingIn(true);
      if (!userInfo) userInfo = await loginAPICall(data);
      if (userInfo.user == null) {
        setIsLoggingIn(false);
        return;
      }
      if (!userInfo.user.is_active) {
        toast.error('Please verify your account');
        navigate(URLS.VERIFY_EMAIL_URL, { state: data });
        return;
      }

      let selectedPE = { orgs: [], selectedOrg: null };
      storeTokens(userInfo.token);
      storeUser(userInfo.user);
      if (userInfo.user.type === PARENT_ENTITY_ADMIN) {
        selectedPE = await singleParentEntitySelectAPICall();
        dispatch(getUserAndInitialiseData());
        if (selectedPE.orgs.length === 0) {
          navigate(URLS.REGISTER_ORG_URL, { replace: true });
        } else if (selectedPE.orgs.length > 1) {
          navigate(URLS.ORGANIZATION_URL);
        } else {
          navigate(URLS.DASHBOARD_URL);
        }
        return;
      }

      if (
        !userInfo.user.is_superuser ||
        userInfo.user.type === ENTITY_ADMIN ||
        selectedPE.orgs.length <= 1
      ) {
        if (!_.isEmpty(selectedPE.selectedOrg)) {
          ReactGA.set({ o_id: selectedPE.selectedOrg.id });
          storeParentEntity(selectedPE.selectedOrg);
        } else {
          if (userInfo.parent_entity)
            ReactGA.set({ o_id: userInfo.parent_entity.id });
          if (userInfo.parent_entity) storeParentEntity(userInfo.parent_entity);
        }
        dispatch(getUserAndInitialiseData());
        navigate(state?.redirectTo ?? URLS.DASHBOARD_URL);
      } else {
        dispatch(getUserAndInitialiseData());
        navigate(URLS.ORGANIZATION_URL);
      }
    } catch (error) {
      let loginError = null;
      const { data: res, status } = error.response ?? {};
      // Special case when user is inactive
      if (res?.deactivated && status === FORBIDDEN_CODE) {
        loginError = { detail: res.message };
      } else {
        loginError = res;
      }
      setIsLoggingIn(false);
      throw loginError;
    }
  }, deps);

  return [isLoggingIn, login];
}
