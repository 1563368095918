import { URLS } from '.';

export const REGIONS_TAB = 'regions';
export const LOCATIONS_TAB = 'locations';

export const LOCATIONS_AND_REGIONS_TABS = [
  {
    id: LOCATIONS_TAB,
    route: URLS.MANAGE_LOCATIONS_SETTINGS_URL,
    name: 'Locations',
    isActiveSubTab: false,
    subTabs: [
      {
        id: 'location-detail',
        heading: 'Location Information',
        subHeading: 'Manage and link customer experience with locations',
        hideSearchFilter: true,
        isSubTab: true,
      },
    ],
  },
  {
    id: REGIONS_TAB,
    route: URLS.MANAGE_REGIONS_SETTINGS_URL,
    name: 'Regions',
    isActiveSubTab: false,
    subTabs: [],
  },
];
