import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';

import {
  ConfigurationPanel,
  Filters,
  HappinessOverTimeGraph,
  Loader,
  QuickStats,
} from 'components';
import {
  BRANCH_EXP_FILTER,
  DATE_RANGE_FILTER,
} from 'components/filters/filterTypes';
import {
  HappinessTrendBlurPreview,
  PieChartBlurPreview,
} from 'components/statBlurWrapper';
import StatBlurWrapper from 'components/statBlurWrapper/StatBlurWrapper';
import { DASHBOARD } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

import Widgets from './components/widgets/Widgets';
import WidgetsPreview from './components/widgets/WidgetsPreview';

import './_dashboard.scss';

function Dashboard() {
  const { t } = useTranslation();
  const dashboardRef = useRef();
  const [filters, setFilters] = useState({
    [DATE_RANGE_FILTER]: {
      startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    },
    [BRANCH_EXP_FILTER]: {},
  });
  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const { parentEntity, user, isLoading } = useSelector(
    (state) => state.authentication,
  );
  const { allowed_analytics: { analytics } = {} } = parentEntity ?? {};

  usePageTitle(DASHBOARD);

  const parseFilters = () => {
    const parsedFilters = {};
    if (!isEmpty(filters[DATE_RANGE_FILTER])) {
      parsedFilters['date_range[0]'] = filters[DATE_RANGE_FILTER].startDate;
      parsedFilters['date_range[1]'] = filters[DATE_RANGE_FILTER].endDate;
    }
    if (!isEmpty(filters, `${BRANCH_EXP_FILTER}.branch`)) {
      parsedFilters.entity = get(filters, `${BRANCH_EXP_FILTER}.branch.value`);
    }
    if (!isEmpty(filters, `${BRANCH_EXP_FILTER}.region`)) {
      parsedFilters.region = get(filters, `${BRANCH_EXP_FILTER}.region.value`);
    }
    if (!isEmpty(filters, `${BRANCH_EXP_FILTER}.experience`)) {
      parsedFilters.experience = get(
        filters,
        `${BRANCH_EXP_FILTER}.experience.value`,
      );
    }
    return parsedFilters;
  };

  const handlePrint = useReactToPrint({
    content: () => dashboardRef.current,
    pageStyle: '@page { size: auto;  margin: 15mm; } }',
    documentTitle: `${parentEntity?.name} comparison_${dayjs().format(
      'YYYYMMDD',
    )}`,
  });

  return !isLoading ? (
    <main ref={dashboardRef}>
      <section className="dashboardHeader">
        <div style={{ width: '-webkit-fill-available' }}>
          <h1>{parentEntity?.name}</h1>
          <p>
            {isEmpty(filters[DATE_RANGE_FILTER])
              ? `${dayjs()
                  .subtract(30, 'days')
                  .format('MM-DD-YYYY')} to ${dayjs().format('MM-DD-YYYY')}`
              : `${dayjs(filters[DATE_RANGE_FILTER].startDate).format(
                  'MM-DD-YYYY',
                )} to ${dayjs(filters[DATE_RANGE_FILTER].endDate).format(
                  'MM-DD-YYYY',
                )}`}
          </p>
        </div>
        <div className="headerDropdownsWrapper">
          <Filters doFetchFiltersData value={filters} onChange={setFilters} />
          <button
            className="configIcon"
            type="button"
            onClick={() => setIsConfigOpen(true)}
          >
            <FontAwesomeIcon icon={faSliders} />
          </button>
          <button
            onClick={handlePrint}
            className="button configIcon configIcon--primary font-body"
            type="button"
          >
            {t('dashboard.print')}
          </button>
        </div>
      </section>
      <section className="chartsWrapper">
        <StatBlurWrapper
          showStat={Boolean(analytics?.segment_analysis)}
          previewUrl={PieChartBlurPreview}
          alt="Quick Stats Preview"
        >
          <QuickStats filters={parseFilters()} />
        </StatBlurWrapper>
        <StatBlurWrapper
          showStat={Boolean(analytics?.nps_trend)}
          previewUrl={HappinessTrendBlurPreview}
          alt="NPS Trend Preview"
        >
          <HappinessOverTimeGraph filters={parseFilters()} />
        </StatBlurWrapper>
      </section>
      {analytics?.per_question_widgets || user?.is_superuser ? (
        <div>
          <ConfigurationPanel
            isOpen={isConfigOpen}
            setIsOpen={setIsConfigOpen}
          />
          <Widgets analytics={analytics} filters={parseFilters()} />
        </div>
      ) : (
        <WidgetsPreview />
      )}
    </main>
  ) : (
    <Loader />
  );
}

export default Dashboard;
