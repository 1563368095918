import { createSlice } from '@reduxjs/toolkit';

import { getBranches, getLocations } from './filterActions';

const INITIAL_STATE = {
  isLoading: false,
  locations: {
    states: [],
    cities: [],
    branches: [],
  },
  branchList: [],
};

const filterSlice = createSlice({
  name: 'filterSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetFiltersSlice: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLocations.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.locations = action.payload;
    });
    builder.addCase(getBranches.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBranches.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBranches.fulfilled, (state, action) => {
      state.isLoading = false;
      state.branchList = action.payload;
    });

    return builder;
  },
});
export const { resetFiltersSlice } = filterSlice.actions;
export default filterSlice;
