import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from 'components';
import { LocationForm } from 'components/forms';
import { useLocationSetup } from 'components/hooks';
import { OnboardingBranchContext } from 'contexts';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { BRANCH_SETUP_STEP, URLS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

import BranchMap from './BranchMap';

export default function BranchSetupScreen() {
  const { branch, setBranch } = useContext(OnboardingBranchContext);
  const { locationId = branch.id } = useParams();
  const navigate = useNavigate();

  usePageTitle('Location Setup');

  const { isLoading, isSubmitting, formik } = useLocationSetup({
    isOnboarding: true,
    locationId,
    location: branch,
    onUpdate: (data) => {
      setBranch(data);
      navigate(`${URLS.BRANCH_FORM_SETUP_URL}/${data.id}`, {
        replace: true,
      });
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={BRANCH_SETUP_STEP} />
        <OnboardingLayout.Form
          title="Location Information"
          subtitle="Add details for your location"
        >
          <LocationForm formik={formik} isOnboarding />
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          isLoading={isSubmitting}
          onClickBack={() => navigate(URLS.BRANCH_LIST_URL)}
          onClickContinue={formik.submitForm}
          continueText="Next"
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        <BranchMap locationData={formik.values} />
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}
