/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useMemo, useState } from 'react';

import { Tooltip } from 'antd';

import Icon from '../icon/Icon';

import './dropdown.scss';

export default function Dropdown({
  id,
  name,
  label,
  value,
  error,
  options,
  disabled,
  onChange,
  isLoading,
  placeholder,
  isSearchable,
  isMultiSelect,
  allowCustomOption,
  customOptionPlaceholder,
  disabledItems = {},
  isEditable,
  chevronStyles = {},
  contentClassName = '',
  labelKey = 'label',
  valueKey = 'value',
  selectStyles = {},
  hideTooltip = false,
}) {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomOptionSelected, setIsCustomOptionSelected] = useState(false);
  const selectedValue = useMemo(
    () =>
      options.filter(({ [valueKey]: val }) => {
        return isMultiSelect ? value?.includes(val) : value === val;
      }),
    [value, options],
  );

  useEffect(() => {
    if (isOpen) {
      setTimeout(
        () =>
          document.addEventListener('click', () => setIsOpen(false), {
            once: true,
          }),
        10,
      );
    }
  }, [isOpen]);

  const handleEditableValueOnChange = (event) => {
    onChange(event.target.value);
  };

  const { placeholderValue, tooltipText } = useMemo(() => {
    let text = placeholder;
    let ttText = '';
    if (isCustomOptionSelected) {
      text = 'Custom';
    } else if (isMultiSelect) {
      if (selectedValue.length) {
        if (selectedValue.length === 1) {
          text = selectedValue[0][labelKey];
          ttText = text;
        } else if (selectedValue.length === options.length) {
          text = 'All';
        } else {
          text = `${selectedValue.length} Selected`;
        }
      }
    } else if (selectedValue[0]?.[labelKey]) {
      text = selectedValue[0]?.[labelKey];
      ttText = text;
    }
    return { tooltipText: ttText, placeholderValue: text };
  }, [
    isCustomOptionSelected,
    isMultiSelect,
    selectedValue,
    labelKey,
    options,
    placeholder,
  ]);

  return (
    <div
      style={selectStyles}
      className={`${isMultiSelect ? 'cxmeter-select-multi' : ''} ${
        error ? 'cxmeter-select-invalid' : ''
      } cxmeter-select ${isOpen ? 'cxmeter-select-open' : ''}`}
    >
      <span>{label}</span>
      <article id={id}>
        <Tooltip
          overlayInnerStyle={{ paddingBlock: 2, minHeight: 15 }}
          zIndex={2000}
          title={isEditable || hideTooltip ? '' : tooltipText}
        >
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className={`cxmeter-select-toggle ${
              selectedValue.length ? '' : 'cxmeter-select-placeholder'
            }`}
            disabled={disabled}
            aria-busy={isLoading ? true : null}
          >
            {isEditable ? (
              <input
                type="text"
                className="dropDownInput"
                value={value}
                onChange={handleEditableValueOnChange}
                onClick={(event) => event.stopPropagation()}
              />
            ) : (
              <span
                className={`text-ellipsis ${
                  isCustomOptionSelected
                    ? 'cxmeter-select-menu-custom-label'
                    : ''
                }`}
              >
                {placeholderValue}
              </span>
            )}
            <Icon style={chevronStyles} type="expand_more" />
          </button>
        </Tooltip>
        <div className={`cxmeter-select-content ${contentClassName}`}>
          {isSearchable ? (
            <input
              className="cxmeter-select-search"
              name="search"
              type="text"
              onInput={({ target: { value: q } }) => setQuery(q)}
              onClick={(e) => e.stopPropagation()}
            />
          ) : null}
          <ul className="cxmeter-select-menu">
            {options
              .filter((option) =>
                option[labelKey].toLowerCase().includes(query.toLowerCase()),
              )
              .map((option) => (
                <li key={option[valueKey]}>
                  <label
                    className={
                      disabledItems[option[valueKey]] ? 'disabled-option' : ''
                    }
                  >
                    <input
                      onClick={(e) => e.stopPropagation()}
                      onChange={({ target: { checked } }) => {
                        setIsCustomOptionSelected(false);
                        if (isMultiSelect) {
                          if (checked) {
                            onChange([
                              ...selectedValue.map((v) => v[valueKey]),
                              option[valueKey],
                            ]);
                          } else {
                            const values = selectedValue.map(
                              (v) => v[valueKey],
                            );
                            values.splice(values.indexOf(option[valueKey]), 1);
                            onChange(values);
                          }
                        } else {
                          onChange(option[valueKey]);
                        }
                      }}
                      type="checkbox"
                      name={name}
                      checked={selectedValue.includes(option)}
                    />
                    <span>{option[labelKey]}</span>
                  </label>
                </li>
              ))}
            {allowCustomOption && (
              <li>
                <label>
                  <input type="hidden" name="" />
                  <span
                    onClick={() => {
                      setIsCustomOptionSelected(true);
                      onChange({ isCustom: true });
                    }}
                  >
                    <button
                      type="button"
                      className="cxmeter-select-menu-custom-label"
                    >
                      Add Custom
                    </button>
                  </span>
                </label>
              </li>
            )}
          </ul>
        </div>
        {isCustomOptionSelected && (
          <label className="control">
            <input
              className="input"
              placeholder={customOptionPlaceholder || 'Enter Custom Option'}
              onChange={({ target }) =>
                onChange({ name: target.value, isCustom: true })
              }
            />
          </label>
        )}
      </article>
      {error}
    </div>
  );
}
