import { useState } from 'react';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Filters } from 'components/filters';
import { SEARCH_FILTER } from 'components/filters/filterTypes';
import { PAGINATION_AND_SORTING_PARAMS } from 'utils/constants';
import {
  EMAIL_TEMPLATES_OVERLAY,
  FEEDBACK_TEMPLATES_OVERLAY,
} from 'utils/constants/manageEmails';

import EmailTemplatesList from './EmailTemplatesList';
import FeedbackTemplatesList from './FeedbackTemplatesList';

function TemplateOverlays({
  formik,
  currentOverlayPreview,
  setCurrentOverlayPreview,
}) {
  const [searchFilter, setSearchFilter] = useState({
    [SEARCH_FILTER]: null,
  });
  const [params, setParams] = useState({
    ...PAGINATION_AND_SORTING_PARAMS,
    search: '',
  });

  const loadMoreHandler = () => {
    setParams((p) => ({ ...p, page: p.page + 1 }));
  };

  const onTemplateConfirm = () => {
    setCurrentOverlayPreview(null);
  };

  const onSearchChange = (searchValue) => {
    const search = searchValue[SEARCH_FILTER];
    setSearchFilter(searchValue);
    setParams((p) => ({ ...p, page: 1, search }));
  };

  return (
    <div className="add-template-container">
      <button
        className="cstm-btn text-cstm-btn"
        style={{ fontSize: 14 }}
        onClick={onTemplateConfirm}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>Back</span>
      </button>
      <h5>
        {currentOverlayPreview === FEEDBACK_TEMPLATES_OVERLAY
          ? 'Attach Feedback Form'
          : 'Import Template'}
      </h5>
      {currentOverlayPreview === FEEDBACK_TEMPLATES_OVERLAY ? null : (
        <div>
          <Filters
            value={searchFilter}
            addMainWrapper={false}
            onChange={onSearchChange}
          />
        </div>
      )}
      {currentOverlayPreview === FEEDBACK_TEMPLATES_OVERLAY ? (
        <FeedbackTemplatesList formik={formik} onConfirm={onTemplateConfirm} />
      ) : null}
      {currentOverlayPreview === EMAIL_TEMPLATES_OVERLAY ? (
        <EmailTemplatesList
          formik={formik}
          apiParams={params}
          loadMoreHandler={loadMoreHandler}
          onConfirm={onTemplateConfirm}
        />
      ) : null}
    </div>
  );
}

export default TemplateOverlays;
