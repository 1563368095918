import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { TABLE_DATA } from 'utils/constants';

import {
  cancelPlan,
  createPaymentMehtod,
  deletePaymentMethod,
  getPaymentMethods,
  getPaymentPlans,
  getSubscribedPlan,
  listInvoices,
  resubscribePlan,
  setDefaultPaymentMethod,
  updatePaymentMethod,
  upgradePlan,
} from './paymentsActions';

const INITIAL_STATE = {
  subscribedPlan: {
    data: null,
    subscribedPlanLoading: false,
  },
  paymentPlans: {
    plans: null,
    isPlansLoading: false,
  },
  paymentMethods: {
    primaryPaymentMethods: [],
    backupPaymentMethods: [],
  },
  isPaymentMethodLoading: false,
  invoices: TABLE_DATA,
  isLoading: false,
  resubscribed: {
    isLoading: false,
  },
  selectedPlanStore: null,
};

const paymentsSlice = createSlice({
  name: 'paymentsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetPaymentSlice: () => INITIAL_STATE,
    setSelectedPlan: (state, action) => {
      state.selectedPlanStore = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listInvoices.pending, (state) => {
      state.invoices.loading = true;
    });
    builder.addCase(listInvoices.rejected, (state) => {
      state.invoices.loading = false;
    });
    builder.addCase(listInvoices.fulfilled, (state, action) => {
      state.invoices.loading = false;
      state.invoices.data = action.payload;
    });
    builder.addCase(getSubscribedPlan.pending, (state, { meta: { arg } }) => {
      const showLoading = arg?.showLoading ?? true;
      state.subscribedPlan.subscribedPlanLoading = showLoading;
    });
    builder.addCase(getSubscribedPlan.rejected, (state) => {
      state.subscribedPlan.subscribedPlanLoading = false;
    });
    builder.addCase(getSubscribedPlan.fulfilled, (state, action) => {
      state.subscribedPlan.subscribedPlanLoading = false;
      state.subscribedPlan.data = action.payload;
    });
    builder.addCase(getPaymentPlans.pending, (state) => {
      state.paymentPlans.isPlansLoading = true;
    });
    builder.addCase(getPaymentPlans.rejected, (state) => {
      state.paymentPlans.isPlansLoading = false;
    });
    builder.addCase(getPaymentPlans.fulfilled, (state, action) => {
      state.paymentPlans = {
        isPlansLoading: false,
        plans: action.payload,
      };
    });
    builder.addCase(resubscribePlan.pending, (state) => {
      state.resubscribed.isLoading = true;
    });
    builder.addCase(resubscribePlan.rejected, (state) => {
      state.resubscribed.isLoading = false;
    });
    builder.addCase(resubscribePlan.fulfilled, (state) => {
      state.resubscribed.isLoading = false;
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      const paymentMethods = action.payload ?? [];
      const primaryPaymentMethods = [];
      const backupPaymentMethods = [];
      paymentMethods.forEach((method) => {
        if (method.is_default) {
          primaryPaymentMethods.push(method);
        } else backupPaymentMethods.push(method);
      });
      state.paymentMethods = {
        primaryPaymentMethods,
        backupPaymentMethods,
      };
      state.isPaymentMethodLoading = false;
    });
    builder.addCase(createPaymentMehtod.fulfilled, (state, action) => {
      const newPaymentMethod = action.payload;
      const { replacedCard } = action.payload ?? {};
      if (replacedCard) {
        if (replacedCard.is_default) {
          state.paymentMethods.primaryPaymentMethods = [replacedCard];
        } else {
          state.paymentMethods.backupPaymentMethods = [replacedCard];
        }
      } else if (newPaymentMethod) {
        if (newPaymentMethod.is_default) {
          state.paymentMethods.primaryPaymentMethods.push(newPaymentMethod);
        } else {
          state.paymentMethods.backupPaymentMethods.push(newPaymentMethod);
        }
      }
      state.isPaymentMethodLoading = false;
    });
    builder.addMatcher(
      isAnyOf(
        getPaymentMethods.pending,
        createPaymentMehtod.pending,
        updatePaymentMethod.pending,
        deletePaymentMethod.pending,
        setDefaultPaymentMethod.pending,
        upgradePlan.pending,
        cancelPlan.pending,
      ),
      (state) => {
        state.isPaymentMethodLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getPaymentMethods.rejected,
        createPaymentMehtod.rejected,
        updatePaymentMethod.rejected,
        updatePaymentMethod.fulfilled,
        deletePaymentMethod.rejected,
        deletePaymentMethod.fulfilled,
        setDefaultPaymentMethod.rejected,
        setDefaultPaymentMethod.fulfilled,
        upgradePlan.rejected,
        upgradePlan.fulfilled,
        cancelPlan.rejected,
        cancelPlan.fulfilled,
      ),
      (state) => {
        state.isPaymentMethodLoading = false;
      },
    );
    return builder;
  },
});

export const { resetPaymentSlice, setSelectedPlan } = paymentsSlice.actions;
export default paymentsSlice;
