/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import _ from 'lodash';

import { surveyTemplatesListAPICall } from 'apis/survey';
import { SurveyPreview, SurveyThumbnail } from 'components';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { FEEDBACK_FORM_STEP, URLS } from 'utils/constants';

import './_listSurveyTemplates.scss';

function ListSurveyTemplates() {
  const [templates, setTemplates] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();

  const getTemplates = async () => {
    const allTemplates = await surveyTemplatesListAPICall();
    if (_.isEmpty(allTemplates)) navigate(URLS.CREATE_SURVEY_TEMPLATE_URL);
    setTemplates(allTemplates);
  };

  const handleTemplateSelect = (template, experience) => {
    setSelectedTemplate({ ...template, experience });
  };

  const handleNextClick = () => {
    navigate(URLS.CREATE_SURVEY_URL, {
      state: {
        redirectParams: {
          preSelectedExperience: {
            id: selectedTemplate.experience,
          },
          surveyId: selectedTemplate._id.$oid,
        },
      },
    });
  };

  const handleSkipClick = () => {
    navigate(URLS.CREATE_SURVEY_TEMPLATE_URL);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={FEEDBACK_FORM_STEP} />
        <OnboardingLayout.Form
          title="Survey Templates"
          subtitle="Here are some templates to help you out"
        >
          {Object.keys(templates).map((item, val) => (
            <div key={val}>
              <h3>{templates[item].name}</h3>
              <div className="templatesList">
                {templates[item].surveys.map((survey, idx) => (
                  <SurveyThumbnail
                    key={idx}
                    data={survey}
                    onChange={() => handleTemplateSelect(survey, item)}
                    selected={selectedTemplate?._id.$oid === survey._id.$oid}
                  />
                ))}
              </div>
            </div>
          ))}
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          onClickSkip={handleSkipClick}
          onClickContinue={handleNextClick}
          disableContinue={!selectedTemplate}
          continueText="Next"
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        {selectedTemplate ? (
          <SurveyPreview survey={selectedTemplate} isTemplate />
        ) : null}
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}

export default ListSurveyTemplates;
