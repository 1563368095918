import React from 'react';

import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RelativeTime from 'components/ui/relativeTime/RelativeTime';
import {
  ENTITY_ADMIN,
  FINANCIAL_ADMIN,
  PARENT_ENTITY_ADMIN,
} from 'utils/constants';

export default function getUserColumns({ onEdit, loggedInUser, t }) {
  return [
    {
      title: t('settings.users.firstName'),
      dataIndex: 'first_name',
      render(_, record) {
        return <p>{record.first_name}</p>;
      },
      onCell: () => ({
        style: {
          maxWidth: 300,
          minWidth: 125,
        },
      }),
      sorter: true,
    },
    {
      title: t('settings.users.lastName'),
      dataIndex: 'last_name',
      sorter: true,
      render(_, record) {
        return <p>{record.last_name}</p>;
      },
      onCell: () => ({
        style: {
          maxWidth: 300,
          minWidth: 125,
        },
      }),
    },
    {
      title: t('settings.users.email'),
      dataIndex: 'email',
      sorter: true,
      render(_, record) {
        return <p>{record.email}</p>;
      },
      onCell: () => ({
        style: {
          maxWidth: 200,
          minWidth: 125,
        },
      }),
    },
    {
      title: t('settings.users.locations'),
      sorter: false,
      render(text, user) {
        if (user.type !== ENTITY_ADMIN) {
          return 'All';
        }
        const selectedEntities = user.entities.map((entity) => ({
          label: `${entity.street_address}, ${entity.city.name}`,
          value: entity.id,
        }));
        return (
          <ul className="user-location-list">
            {selectedEntities.map((e) => (
              <li key={e.value}>{e.label}</li>
            ))}
          </ul>
        );
      },
    },
    {
      title: t('settings.users.permissions'),
      dataIndex: 'type',
      sorter: true,
      render(_, record) {
        return (
          <p>
            {
              {
                [PARENT_ENTITY_ADMIN]: 'Organization Admin',
                [ENTITY_ADMIN]: 'Location Admin',
                [FINANCIAL_ADMIN]: 'Financial Admin',
              }[record.type]
            }
          </p>
        );
      },
    },
    {
      title: t('settings.users.lastActivity'),
      dataIndex: 'last_activity_at',
      sorter: true,
      render: (text, record) =>
        record.last_activity_at ? (
          <RelativeTime date={record.last_activity_at} />
        ) : (
          '-'
        ),
      onCell: () => ({
        style: {
          minWidth: 150,
          whiteSpace: 'nowrap',
        },
      }),
    },
    {
      title: t('settings.users.status.title'),
      dataIndex: 'is_active',
      sorter: true,
      render(_, record) {
        return (
          <span className={`active-pill ${record.is_active ? 'active' : ''}`}>
            {record.is_active
              ? t('settings.users.status.active')
              : t('settings.users.status.inactive')}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      render(_, record) {
        return loggedInUser?.id === record.id ? null : (
          <button
            type="button"
            className="button"
            onClick={() => {
              onEdit(record);
            }}
          >
            {' '}
            <FontAwesomeIcon icon={faPencil} />
          </button>
        );
      },
    },
  ];
}
