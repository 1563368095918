import { Tooltip } from 'antd';

const dayjs = require('dayjs');

function RelativeTime({
  date,
  tooltipDateFormat = 'MMMM DD • h:mm A',
  showTooltip = true,
  styles = {},
}) {
  return (
    <Tooltip
      title={dayjs(date).format(tooltipDateFormat)}
      {...(showTooltip ? {} : { open: false })}
    >
      <time style={{ cursor: 'default', ...styles }}>
        {dayjs(date).fromNow()}
      </time>
    </Tooltip>
  );
}

export default RelativeTime;
