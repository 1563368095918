import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { isEmpty } from 'lodash';

import { Loader } from 'components';
import { getUserAndInitialiseData } from 'redux/authentication/authenticationActions';
import { setAppLoading } from 'redux/authentication/authenticationSlice';
import { AppRoutes } from 'routes';
import { getUser } from 'utils/helpers';
import 'utils/helpers/dayjsConfig';

import './i18n';

import 'react-toastify/dist/ReactToastify.css';
import 'scss/App.scss';
import 'scss/index.scss';

function App() {
  const dispatch = useDispatch();
  const userFromStorage = getUser();

  useEffect(() => {
    if (!isEmpty(userFromStorage)) {
      dispatch(getUserAndInitialiseData());
    } else {
      dispatch(setAppLoading(false));
    }
  }, [userFromStorage]);

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <AppRoutes />
      </Suspense>
      <ToastContainer autoClose={6000} />
    </div>
  );
}

export default App;
