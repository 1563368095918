export const onlyNumbersRegex = /^[0-9]+$/;
export const onlyLettersAndSpacesRegex = /^[A-Za-z\s]+$/;
export const organizationNameRegex = /^[a-zA-Z0-9.,\-&#() ]+$/;
export const onlyLettersAndNumbersRegex = /^[a-zA-Z0-9\s]+$/;
export const phoneNumberRegex =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/;
export const emailRegexMax128Chars =
  /^(?=.{1,128}$)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/;
