import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { experienceListAPICall } from 'apis/cxmeter';
import { ComponentLoader } from 'components';
import { URLS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

import './_createSurveyTemplate.scss';

function CreateSurveyTemplate() {
  const { state, pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const navigate = useNavigate();
  usePageTitle('Create a template');

  const getExperiences = async () => {
    setLoading(true);
    const allExperiences = await experienceListAPICall();
    setExperiences(allExperiences);
    setLoading(false);
    setSelectedExperience(allExperiences[0].id);
  };

  const handleExperienceSelect = (id) => {
    setSelectedExperience(id);
  };

  const handleNextClick = () => {
    const url = pathname.startsWith(URLS.SIGNUP_URL)
      ? URLS.CREATE_SURVEY_URL
      : URLS.ADMIN_CREATE_SURVEY_URL;
    navigate(url, {
      state: {
        ...state,
        redirectParams: {
          preSelectedExperience: { id: selectedExperience },
        },
      },
    });
  };

  useEffect(() => {
    getExperiences();
  }, []);

  return (
    <div>
      <Row className="rowContainer">
        <Col md={6}>
          {loading && <ComponentLoader />}
          <div className="formContainer" style={{ minHeight: 700 }}>
            <div className="fl-1">
              <div className="logo">
                <img src="/assets/images/appLogo.svg" alt="logo" width={120} />
              </div>
              <h1>Select Experience to continue</h1>
              <ul className="experienceList">
                {experiences.map((exp) => (
                  <li key={exp.id}>
                    <input
                      hidden
                      type="radio"
                      name="experience-input"
                      id={`input-radio-${exp.id}`}
                      onChange={() => handleExperienceSelect(exp.id)}
                    />
                    <label
                      htmlFor={`input-radio-${exp.id}`}
                      className={`experienceLabel ${
                        selectedExperience === exp.id &&
                        'selectedExperienceLabel'
                      }`}
                    >
                      {exp.name}
                    </label>
                  </li>
                ))}
                {experiences.length === 0 && (
                  <p>No Experiences added for current business type</p>
                )}
              </ul>
            </div>
            {selectedExperience && (
              <button
                className="button button-primary"
                onClick={handleNextClick}
              >
                Next
              </button>
            )}
          </div>
        </Col>
        <Col md={6} className="d-none d-md-block">
          <div className="infoBanner">
            <h2>
              🚀
              <br />
              The simplest way to gather customer feedback for your business
            </h2>
            <p>
              Obtain valuable metrics and insights to improve your customer
              experience and profitability
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CreateSurveyTemplate;
