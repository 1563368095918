/* eslint-disable no-new */

/* eslint-disable no-promise-executor-return */
import { toast } from 'react-toastify';

import Compressor from 'compressorjs';
import dayjs from 'dayjs';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {
  get,
  isNaN,
  isNumber,
  isString,
  mapKeys,
  omit,
  toNumber,
} from 'lodash';

import {
  BRANCH_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
  LOCATION_FILTER,
  REVIEW_FILTER,
  SEARCH_FILTER,
  STATUS_FILTER,
} from 'components/filters/filterTypes';
import i18n from 'i18n';
import {
  DEFAULT_PAGE_SIZE,
  FORBIDDEN_CODE,
  HAPPY,
  MIN_IMAGE_SIZE_AFTER_COMPRESSION,
  MIN_IMAGE_SIZE_FOR_COMPRESSION,
  NEUTRAL,
  UNAUTHORIZED_CODE,
  UNHAPPY,
} from 'utils/constants';

export function getFilters(filterStore, page = null) {
  const filters = {};
  if (get(filterStore, SEARCH_FILTER)) {
    filters.search_text = get(filterStore, SEARCH_FILTER);
  }
  if (get(filterStore, BRANCH_FILTER)) {
    filters.entity = get(filterStore, `${BRANCH_FILTER}.value`);
    filters.entity_name = get(filterStore, `${BRANCH_FILTER}.label`);
  }
  if (get(filterStore, EXPERIENCE_FILTER)) {
    filters.experience = get(filterStore, `${EXPERIENCE_FILTER}.value`);
    filters.experience_name = get(filterStore, `${EXPERIENCE_FILTER}.label`);
  }
  if (get(filterStore, REVIEW_FILTER)) {
    filters.segment = get(filterStore, `${REVIEW_FILTER}.value`);
    filters.segment_name = get(filterStore, `${REVIEW_FILTER}.label`);
  }
  if (get(filterStore, STATUS_FILTER)) {
    filters.status = get(filterStore, `${STATUS_FILTER}.value`);
    filters.status_name = get(filterStore, `${STATUS_FILTER}.label`);
  }
  // todo: translate these to filters

  // if (filterStore.selectedSegment !== 0) {
  //   filters.segment = filterStore.selectedSegment;
  // }
  // if (filterStore.selectedExperience !== 0) {
  //   filters.experience = filterStore.selectedExperience;
  // }
  // if (!(filterStore.selectedDrivers.length === 1 && filterStore.selectedDrivers[0] === 0)) {
  //   for (let i = 0; i < filterStore.selectedDrivers.length; i += 1) {
  //     filters[`drivers[${i}]`] = filterStore.selectedDrivers[i];
  //   }
  // }
  if (
    get(filterStore, `${DATE_RANGE_FILTER}.startDate`) &&
    get(filterStore, `${DATE_RANGE_FILTER}.endDate`)
  ) {
    filters.start_date = get(filterStore, `${DATE_RANGE_FILTER}.startDate`);
    filters.end_date = get(filterStore, `${DATE_RANGE_FILTER}.endDate`);
    filters.range_label = get(filterStore, `${DATE_RANGE_FILTER}.label`);
  }
  if (get(filterStore, `${LOCATION_FILTER}.branch`)) {
    filters.street_address = get(filterStore, `${LOCATION_FILTER}.branch`);
  }
  if (get(filterStore, `${LOCATION_FILTER}.city`)) {
    filters.city = get(filterStore, `${LOCATION_FILTER}.city`);
  }
  if (get(filterStore, `${LOCATION_FILTER}.state`)) {
    filters.state = get(filterStore, `${LOCATION_FILTER}.state`);
  }
  if (filterStore.last_viewed_id) {
    filters.last_viewed_id = filterStore.last_viewed_id;
  }
  if (filterStore.sort_order) {
    filters.sort_order = filterStore.sort_order;
  }
  // if (filterStore.selectedTimeline) {
  //   filters.timeline = filterStore.selectedTimeline;
  // }
  if (page) {
    filters.page = page;
  }
  return filters;
}

export const arrayIncludesObj = (arr, key, valueToCheck) =>
  // eslint-disable-next-line eqeqeq, implicit-arrow-linebreak
  arr.some((value) => value[key] == valueToCheck);

export function storeTokens(token) {
  localStorage.setItem('access_token', token.access);
  localStorage.setItem('refresh_token', token.refresh);
}

export function storeAccessToken(access) {
  localStorage.setItem('access_token', access);
}

export function getAccessToken() {
  return localStorage.getItem('access_token');
}

export function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}

export function storeUser(user) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function getUser() {
  return JSON.parse(localStorage.getItem('user'));
}

export function storeParentEntity(parentEntity) {
  localStorage.setItem('parent_entity', JSON.stringify(parentEntity));
}

export function getParentEntity() {
  return JSON.parse(localStorage.getItem('parent_entity'));
}

export function clearLocalStorge() {
  localStorage.clear();
}

export function findKeyInArray(arr, key, value) {
  return arr.find((element) => element[key] === value);
}

export function findInArray(arr, value) {
  return arr.find((element) => element === value);
}

export function findArrayInArrays(arr, arrToFind, key) {
  return arrToFind.map((element) => arr.find((el) => el[key] === element));
}

export function getLocationString(branch, city, state) {
  let location = '';
  if (branch) location += branch;
  if (city) {
    if (location) location += ', ';
    location += city.city_name;
  }
  if (state) {
    if (location) location += ', ';
    location += state.state_name;
  }
  if (location) return location;
  return null;
}

export function getDateRange(startDate, endDate) {
  if (!startDate && !endDate) return null;
  return `${startDate} to ${endDate}`;
}

export function convertToTitleCase(str) {
  return str
    .replaceAll('_', ' ')
    .toLowerCase()
    .replace(/\b\w/g, (s) => s.toUpperCase());
}

export function convertToSnakeCase(str) {
  return str.replaceAll(' ', '_').toLowerCase();
}

export function getUserFullNameOrEmail(user) {
  return user.first_name || user.last_name
    ? `${user.first_name} ${user.last_name}`
    : user.email;
}

export function getUniqueObjects(array) {
  const result = [];
  const map = new Map();
  // eslint-disable-next-line no-restricted-syntax
  for (const item of array) {
    if (!map.has(item.id)) {
      map.set(item.id, true);
      result.push({
        id: item.id,
        name: item.name,
      });
    }
  }
  return result;
}

export function getImageNameFromUrl(url) {
  const arr = url.split('/');
  const len = arr.length;
  for (let i = len - 1; i > 0; i -= 1) {
    if (arr[i]) {
      return `${arr[i].substring(0, 5)}...`;
    }
  }
  return 'image...';
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getErrorString(error) {
  if (typeof error.response.data === 'string') return error.response.data;
  let textError = '';
  const arr = Object.values(error.response.data).flat();
  for (let i = 0; i < arr.length; i += 1) {
    textError += `${arr[i]}\n`;
  }
  return textError;
}

export function toFormData(data) {
  return Object.entries(data).reduce(
    // eslint-disable-next-line no-sequences
    (d, e) => (d.append(...e), d),
    new FormData(),
  );
}

export function getBlobType(type) {
  if (type.toLowerCase().includes('svg')) {
    return 'svg';
  }
  return 'png';
}

export function getDiffInDaysMonthYear(data) {
  const diff = dayjs.duration(dayjs(new Date()).diff(dayjs(data)));
  const days = diff.days();
  const months = diff.months();
  const years = diff.years();
  const strDiff = `${years ? `${years} ${years > 1 ? 'years' : 'year'}` : ''}${
    months
      ? `${years ? (days ? ',' : ' and') : ''} ${months} ${
          months > 1 ? 'months' : 'month'
        }`
      : ''
  } ${
    days
      ? `${years || months ? 'and' : ''} ${days} ${days > 1 ? 'days' : 'day'}`
      : ''
  }`;
  return strDiff.trim() ? strDiff.trim() : '0 months';
}

export function copyArrayOfObjects(arrayOfObjects) {
  return arrayOfObjects ? arrayOfObjects.map((obj) => ({ ...obj })) : [];
}

export const serializeAxiosError = (error) => {
  const axiosError = omit(error, ['config', 'request', 'toJSON']);
  axiosError.response = omit(axiosError.response, ['request', 'config']);
  return axiosError;
};

export const hideErrorToast = (error) => {
  const { data, status } = error.response ?? {};
  if (
    status === FORBIDDEN_CODE &&
    (data?.access === 'blocked' || data?.deactivated)
  ) {
    return true;
  }
  if (status === UNAUTHORIZED_CODE && data?.code === 'user_inactive') {
    toast.error(data.detail);
    return true;
  }
  return false;
};

export function showErrorToast(error, rejectWithValue) {
  if (error.response?.headers['content-type'] === 'text/html; charset=utf-8') {
    toast.error(error.message ? error.message : 'Something Went wrong');
    return Promise.reject(error);
  }

  const shouldHideErrorToast = hideErrorToast(error);

  if (shouldHideErrorToast) {
    return error;
  }

  if (error.response?.status === 500 && error.response.statusText) {
    toast.error(error.response.statusText);
    return Promise.reject(error);
  }

  if (rejectWithValue) {
    const axiosError = serializeAxiosError(error); // To eliminate redux non-serializable values warning
    return rejectWithValue(axiosError);
  }

  if (error.response?.data) {
    toast.error(getErrorString(error));
  } else {
    toast.error(error.message ? error.message : 'Something Went wrong');
  }
  return Promise.reject(error);
}

export function sortObject(obj, index) {
  const items = Object.keys(obj).map((key) => [key, obj[key]]);
  items.sort((first, second) => {
    if (first[index] > second[index]) {
      return 1;
    }
    return -1;
  });
  return items;
}

export function arrayMove(array, sourceId, destinationId) {
  const sourceIndex = array.findIndex((item) => item.id === sourceId);
  const destIndex = array.findIndex((item) => item.id === destinationId);
  const [source] = array.splice(sourceIndex, 1);
  array.splice(destIndex, 0, source);
  return array;
}

export function getRandomColor() {
  const colors = ['rgb(49, 35, 72)', 'rgb(60 117 104)', 'rgb(210 132 80)'];
  return colors[Math.floor(Math.random() * colors.length)];
}

export function dataURLtoFile(dataurl, name = 'avatar.png') {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], name, { type: mime });
}

export const markupToText = (markup) => markup.replace(/<[^>]*>/g, '');

export const renameAndOmitKeys = (obj, keyMap, keysToOmit) => {
  const renamedObj = mapKeys(obj, (value, key) => {
    if (Object.hasOwnProperty.call(keyMap, key)) {
      return keyMap[key];
    }
    return key;
  });
  return omit(renamedObj, keysToOmit);
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return dayjs(date).format('YYYY-MM-DD');
};

export const generateRandomPassword = (length = 15) => {
  const keys = {
    upperCase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    lowerCase: 'abcdefghijklmnopqrstuvwxyz',
    number: '0123456789',
    symbol: '!@#$%^&*()_+~\\`|}{[]:;?><,./-=',
  };

  const getKey = [
    function upperCase() {
      return keys.upperCase[Math.floor(Math.random() * keys.upperCase.length)];
    },
    function lowerCase() {
      return keys.lowerCase[Math.floor(Math.random() * keys.lowerCase.length)];
    },
    function number() {
      return keys.number[Math.floor(Math.random() * keys.number.length)];
    },
    function symbol() {
      return keys.symbol[Math.floor(Math.random() * keys.symbol.length)];
    },
  ];

  let password = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length - 4; i++) {
    password += getKey[Math.floor(Math.random() * getKey.length)]();
  }
  // password must have an uppercase, lowercase, number, and special character
  password += getKey[0]();
  password += getKey[1]();
  password += getKey[2]();
  password += getKey[3]();

  return password;
};

export const changeOpacity = (originalColor, newOpacity) => {
  const rgbaRegex = /rgba\((\d+), (\d+), (\d+), ([\d.]+)\)/;
  const match = originalColor.match(rgbaRegex);

  const [, red, green, blue] = match;
  const newColor = `rgba(${red}, ${green}, ${blue}, ${newOpacity})`;

  return newColor;
};

export const getSegmentId = (segment) => {
  switch (segment) {
    case HAPPY:
      return 1;
    case NEUTRAL:
      return 2;
    case UNHAPPY:
      return 3;
    default:
      return null;
  }
};

export const formatTime = (time) => {
  const seconds = `${Math.floor(time / 1000)}`.padStart(2, '0');
  const minutes = `${Math.floor(seconds / 60)}`.padStart(2, '0');
  const hours = `${Math.floor(minutes / 60)}`.padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

export const deltaDaysFromNow = (dateInStr) => {
  const date = new Date(dateInStr);
  return Math.ceil((date - new Date()) / (1000 * 60 * 60 * 24));
};

export const keepOnlyKeys = (obj, keysToKeep) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const filteredValue = {};
      keysToKeep.forEach((k) => {
        if (Object.prototype.hasOwnProperty.call(value, k)) {
          filteredValue[k] = value[k];
        }
      });
      return [key, filteredValue];
    }),
  );
};

export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const isNumberOrParsableString = (value) => {
  return (
    (!Number.isNaN(value) && isNumber(value)) ||
    (isString(value) && !isNaN(toNumber(value)))
  );
};
const getReducedDimensions = (
  originalWidth,
  originalHeight,
  reductionPercentage,
  minWidth,
  minHeight,
) => {
  // Check if original dimensions are already smaller than minimum
  if (originalWidth < minWidth || originalHeight < minHeight) {
    return { width: originalWidth, height: originalHeight };
  }

  // Calculate desired dimensions after percentage reduction
  const desiredWidth = originalWidth * (1 - reductionPercentage / 100);
  const desiredHeight = originalHeight * (1 - reductionPercentage / 100);

  // Check if desired dimensions are smaller than original dimensions
  if (desiredWidth >= originalWidth && desiredHeight >= originalHeight) {
    return { width: originalWidth, height: originalHeight };
  }

  // Calculate aspect ratio of original image
  const aspectRatio = originalWidth / originalHeight;

  // Initialize new dimensions with desired values
  let newWidth = desiredWidth;
  let newHeight = desiredHeight;

  // Adjust dimensions to maintain aspect ratio and meet minimum size constraints
  if (newWidth / aspectRatio < minHeight) {
    newWidth = minHeight * aspectRatio;
  } else if (newHeight * aspectRatio < minWidth) {
    newHeight = minWidth / aspectRatio;
  }

  // Ensure dimensions do not fall below minimum size constraints
  newWidth = Math.max(newWidth, minWidth);
  newHeight = Math.max(newHeight, minHeight);

  // Return resized dimensions
  return { width: Math.round(newWidth), height: Math.round(newHeight) };
};

export const compressImage = ({
  file,
  maxImageSize,
  reductionPercentage,
  quality,
  minWidth,
  minHeight,
} = {}) => {
  return new Promise((resolve, reject) => {
    if (file.size > maxImageSize) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({ isTooLarge: true });
    } else if (file.size <= MIN_IMAGE_SIZE_FOR_COMPRESSION) {
      resolve(file);
    } else {
      const image = new Image();
      image.onload = function () {
        const { width, height } = this;
        const { width: newWidth, height: newHeight } = getReducedDimensions(
          width,
          height,
          reductionPercentage,
          minWidth,
          minHeight,
        );
        new Compressor(file, {
          quality,
          maxWidth: newWidth,
          maxHeight: newHeight,
          success(result) {
            let compressedFile = result;
            if (
              compressedFile.size < MIN_IMAGE_SIZE_AFTER_COMPRESSION ||
              compressedFile.size > file.size
            ) {
              compressedFile = file;
            } else if (result instanceof Blob) {
              compressedFile = new File([result], result.name, {
                type: result.type,
                lastModified: result.lastModified,
              });
            }
            resolve(compressedFile);
          },
        });
      };
      image.src = URL.createObjectURL(file);
    }
  });
};

export const populateFormikErrors = (errors, formik) => {
  let nonFieldError = '';
  Object.keys(errors).forEach((key) => {
    const error = errors[key];
    // eslint-disable-next-line no-prototype-builtins
    if (formik.values.hasOwnProperty(key) && error) {
      const errorMessage = Array.isArray(error) ? error[0] : error;
      formik.setFieldError(key, errorMessage);
    } else if (!nonFieldError) {
      nonFieldError = error;
    }
  });
  formik.setFieldError('nonFieldError', nonFieldError);
};

const customEntityTransform = (entity, text) => {
  const { data, type } = entity;
  if (type === 'LINK' && data.isSurveyLink) {
    return `<a href="" id="survey-link">${text}</a>`;
  }
  return null;
};

export const contentStateToHtml = (content) => {
  const rawContent = convertToRaw(content);
  const html = draftToHtml(rawContent, {}, false, customEntityTransform);
  return html;
};

export const showTranslatedToast = (text) => {
  if (i18n?.t) {
    const translatedText = i18n?.t(text);
    toast.success(translatedText);
  }
};

export const capitalizeFirstWord = (str) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const copyToClipboard = (text, textTitle) => {
  if (text && navigator.clipboard) {
    navigator.clipboard.writeText(text);
    toast.success(`${textTitle} copied successfully.`);
  } else {
    toast.error(`Oops! ${textTitle} can't be Copied.`);
  }
};

export const getAntdPaginationProps = ({
  data,
  currentPage,
  onChangePagination,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  showSizeChanger = false,
}) => {
  return {
    className: 'cx-meter-antd-pagination',
    showSizeChanger,
    current: currentPage,
    total: data.count,
    defaultPageSize,
    onChange: onChangePagination,
    showTotal: (total) =>
      `${(currentPage - 1) * defaultPageSize + 1}-${
        (currentPage - 1) * defaultPageSize + data.results.length
      } of ${total} items`,
  };
};

export const getUserTimezone = () => {
  const timeZoneByDayJs = dayjs.tz.guess();
  return timeZoneByDayJs;
};

export const formatNumberToK = (num) => {
  if (!num || num < 1000) {
    return num?.toString() || ''; // Return the number as is if it's less than 1000
  }
  const thousands = Math.floor(num / 1000); // Calculate the number of thousands
  const remainder = num % 1000; // Check if there's any remainder
  return remainder === 0 ? `${thousands}K` : `${thousands}K+`; // Return with or without "+" based on the remainder
};
