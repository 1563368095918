import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Input, Tag } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { get } from 'lodash';

import { Modal } from 'components/ui';
import { shareQr } from 'redux/qrSetup/qrSetupActions';
import { emailRegexMax128Chars } from 'utils/constants/regex';

import '../_qrSetup.scss';

export default function ShareModal({ qr, onClose }) {
  const parentEntity = useSelector((s) => s.authentication.parentEntity);
  const { isEmailingQr } = useSelector((s) => s.qrSetup);
  const [form] = useForm();
  const dispatch = useDispatch();
  const [, forceUpdate] = useState(0);

  const onSubmit = (vals) => {
    dispatch(
      shareQr({
        ...vals,
        email_addresses: vals.email_addresses.split(',').map((em) => em.trim()),
      }),
    ).then((action) => {
      if (!action.error) {
        onClose();
      }
    });
  };
  const isEmail = (email = '') => {
    return email.match(emailRegexMax128Chars);
  };

  useEffect(() => {
    form.setFieldsValue({
      body: `Hello,\n\nYou have been invited to provide your feedback regarding your recent experience with ${parentEntity.name}. We would appreciate if you could complete the following brief survey by clicking on the following link.\n\n${qr.url}\n\nOn behalf of ${parentEntity.name}, thank you in advance for your help.\nRegards,\n${parentEntity.name}`,
    });
  }, [qr]);

  const headerTitle =
    get(qr, 'entity_experience.entity.name', '') &&
    get(qr, 'entity_experience.experience.name', '')
      ? `${get(qr, 'entity_experience.entity.name', '')} - ${get(
          qr,
          'entity_experience.experience.name',
          '',
        )}`
      : 'Not Linked';

  return (
    <Modal onClose={onClose} open>
      <Modal.Header onClose={onClose} title="Share Survey" />
      <Modal.Body>
        <Form
          form={form}
          onFinish={onSubmit}
          onChange={() => forceUpdate((v) => !v)}
        >
          <div className="qrDetail">
            {headerTitle ? (
              <p className="text-center">
                <b>{headerTitle}</b>
              </p>
            ) : null}
            <div className="heading mb-30">
              <h3>Emails</h3>
              <Form.Item
                validateTrigger="onChange"
                rules={[
                  { required: true, message: 'This field is required!' },
                  {
                    validator: (m, val) => {
                      if (!val) {
                        return Promise.resolve();
                      }
                      const emails = val.split(',');
                      if (emails.length > 5) {
                        return Promise.reject(
                          new Error('Maximum of 5 emails can be added'),
                        );
                      }
                      const invalidEmail = emails.find(
                        (email) => !email || !isEmail(email.trim()),
                      );
                      if (typeof invalidEmail === 'string') {
                        let errorMessage = `"${invalidEmail}" is not a valid email address`;
                        if (invalidEmail.length > 128)
                          errorMessage = `"${invalidEmail}" can't exceed 128 characters`;
                        return Promise.reject(new Error(errorMessage));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                name="email_addresses"
              >
                <Input.TextArea placeholder="Add comma separated emails here" />
              </Form.Item>
              <div className="mb-30">
                {form
                  .getFieldValue('email_addresses')
                  ?.split(',')
                  .map((email, i) => (
                    <Tag
                      key={i + email.trim()}
                      style={{
                        wordBreak: 'break-all',
                        whiteSpace: 'normal',
                        margin: '3px',
                        ...(!isEmail(email.trim())
                          ? { border: '1px solid red' }
                          : {}),
                      }}
                    >
                      {email.trim()}
                    </Tag>
                  ))}
              </div>

              <h3>Content</h3>
              <Form.Item
                rules={[{ required: true, message: 'This field is required!' }]}
                name="body"
              >
                <Input.TextArea placeholder="Email Body" autoSize />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer
        primaryLabel="Share"
        primaryAction={form.submit}
        isLoading={isEmailingQr}
        disablePrimaryAction={isEmailingQr}
        secondaryAction={onClose}
        secondaryLabel="Back"
      />
    </Modal>
  );
}
